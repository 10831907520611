import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { getListFromResponse } from "../../helpers/utility";
import {
  attachAccessTokenToUrlQueryParams,
  replaceDynamics,
  updateQueryParams
} from "../../helpers/url";
import { NETWORK_ERROR_MESSAGE } from "../../constants/general";

export const SHOW_SNACKBAR = "CORE/SHOW_SNACKBAR";
export const  SHOW_ALERT = "CORE/SHOW_ALERT";
export const  CLEAR_ALERT = "CORE/CLEAR_ALERT";
export const CLEAR_SNACKBAR = "CORE/CLEAR_SNACKBAR";
export const SET_COURSE_LIST = "CORE/SET_COURSE_LIST";
export const UPDATE_COURSE_LIST = "CORE/UPDATE_COURSE_LIST";
export const SET_CURRENT_COURSE = "CORE/SET_CURRENT_COURSE";
export const SET_CURRENT_MENU_ITEM = "CORE/SET_MENU";
export const SET_CATEGORIES= "CORE/SET_CATEGORIES";
export const SET_COURSE_CATEGORIES = "CORE/SET_COURSE_CATEGORIES";
export const SET_PAGE_SECTION_TITLE = "CORE/SET_SECTION_TITLE";
export const SET_PAGE_BACK_BUTTON = "CORE/SET_BACK_BUTTON";
export const SET_NOTIFICATIONS = "CORE/SET_NOTIFICATIONS";
export const LOADING_COURSE =  "CORE/LOADING_COURSES";
/**
 * Remove backbutton
 * @param {Object} params {action: '', location: '/}
 */
export const setNotifications = data => {
  return dispatch => {
    dispatch({ type: SET_NOTIFICATIONS, payload: { notifications: data } });
  };
};
/**
 * Remove backbutton
 * @param {Object} params {action: '', location: '/}
 */
export const clearBackButton = params => {
  return dispatch => {
    dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: params.action, location: params.location } });
  };
};


export const setCurrentMenu = currentPage => {
  return dispatch => {
    dispatch({ type: SET_CURRENT_MENU_ITEM, setCurrentPage: {currentPage} });
  };
};
export const setSectionTitle = currentPage =>{
  return dispatch => {
    dispatch({ type: SET_PAGE_SECTION_TITLE, setPageTitle: {...currentPage} });
  };
};

export const showSnackbar = config => {
  return dispatch => {
    dispatch({ type: SHOW_SNACKBAR, payload: {config} });
  };
};
export const showAlert = config => {
  return dispatch => {
    dispatch({ type: SHOW_ALERT, payload: {...config} });
  };
};
export const clearAlert = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ALERT });
  };
};
export const clearSnackbar = () => {
  return dispatch => {
    dispatch({ type: CLEAR_SNACKBAR });
  };
};

export const getCourseList = queryParams => {
  return dispatch => {
    let url = apiRoutes.COURSE_LIST;
    if (queryParams) {
      url = updateQueryParams(url, queryParams);
    }
    if(queryParams.addToken){
      url = attachAccessTokenToUrlQueryParams(url);
    }
    return http.get(url).then(
      response => {
        if(response.data.data.total){
          dispatch({
            type: SET_COURSE_LIST,
            payload: {courseList : getListFromResponse(response, 'courses'), loadingCourse : false}
          });
        }else if(!response.data.data.total && queryParams){
          dispatch({
            type: LOADING_COURSE, 
            payload: { loadingCourse: false}
          });
          dispatch(showSnackbar({ message: `No Courses found, for this filter criteria.` }));
        } else if(!response.data.data.total && !queryParams){
          dispatch({
            type: LOADING_COURSE, 
            payload: { loadingCourse: false}
          });
        }
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course list: ${error.message}` }));
        }
        dispatch({type: SET_COURSE_LIST, payload: {courseList : {}}});
      }
    );
  };
};
export const loadingCourse = () => {
  return function(dispatch) {
    dispatch({
      type: LOADING_COURSE, 
      payload: { loadingCourse: true}
    });
  };
};
export const appendToCourseList = queryParams => {
  return dispatch => {
    let url = apiRoutes.COURSE_LIST;
    if (queryParams) {
      url = updateQueryParams(url, queryParams);
    }
    if(queryParams.addToken){
      url = attachAccessTokenToUrlQueryParams(url);
    }
    return http.get(url).then(
      response => {
        dispatch({
          type: UPDATE_COURSE_LIST,
          payload: {courseList : getListFromResponse(response, 'courses')}
        });
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course list: ${error.message}` }));
        }
        dispatch({type: SET_COURSE_LIST, payload: {courseList : {}}});
      }
    );
  };
};
export const getCourseDetail = id => {
  return dispatch => {
    return http.get(replaceDynamics(apiRoutes.COURSE_DETAIL, {id})).then(
      response => {
        dispatch({type: SET_CURRENT_COURSE, payload: { currentCourse: response.data.data}});
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting course detail: ${error.message}` }));
        }
        dispatch({type: SET_CURRENT_COURSE, payload: {currentCourse : {}}});
      }
    );
  };
};

export const getCategories = (params) => {
  return dispatch => {
    let url = apiRoutes.CATEGORY_LIST;
    if(params){
      url = apiRoutes.CATEGORY_LIST+params.id;
    }
    url = attachAccessTokenToUrlQueryParams(url);
    return http.get(url).then(
      response => {
        // if(response.data.data.total){
        dispatch({
          type: SET_CATEGORIES,
          payload: {categories : response.data.data}
        });
        dispatch({
          type: LOADING_COURSE, 
          payload: { loadingCourse: false}
        });
        // }else if(!response.data.data.total){
        //   dispatch(showSnackbar({ message: `No Courses found, for this filter criteria.` }));
        // }
        
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting category list: ${error.message}` }));
        }
        dispatch({type: SET_COURSE_LIST, payload: {courseList : {}}});
      }
    );
  };
};
