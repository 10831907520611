import React, {Fragment, useRef } from 'react';
import './PageTitle.scss';

import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { getDiscussionOnUserTakenTopics, getDiscussionTopics, LOAD_DISCUSSION } from '../../store/actions/discussionActions';
import { connect, useSelector } from 'react-redux';
import { getListFromResponse } from '../../helpers/utility';

const SidebarTabTitle = props => {
  const ref = useRef(props.title);
  const user = useSelector(state => state.userState.user);
  console.log(user);
  console.log(props);
  const toggleClick = (e)=>{
    
    document.querySelectorAll('.sidebarTabTitle').forEach(el => {
      el.classList.remove('hasHr');
      el.classList.add('noHr');
    });
    if(e.target.classList.contains('noHr')){
      e.target.classList.add('hasHr');
      e.target.classList.remove('noHr');
    }else{
      
      e.target.classList.remove('hasHr');
      e.target.classList.add('noHr');
     
    }
    
    if(e.target.id.toLowerCase() === 'all discussion') {
      props.dispatch(getDiscussionTopics());
    } else if(e.target.id.toLowerCase() === 'courses i’ve taken') {
      props.dispatch(getDiscussionOnUserTakenTopics(user.id));
    } 
    
  };
  /**
   * isTabbed is used to display this page title in table format, see community section side bar
   * hasHr is used to add a border-bottom to the titme
   */
  return (
     
    <Fragment>
     
      <h3 ref={ref} onClick={toggleClick} id={props.title} 
        className={`${props.classStyle} align-self-center  sectionTitle  
        sidebarTabTitle ${props.isTab ? 'greyText ' : 'font-weight-600'} ${props.hasHr ? 'hasHr' : 'noHr'} `} >
        {props.title }
       
      </h3> 

      
    </Fragment>
    
  );
};
export default connect()(SidebarTabTitle);
