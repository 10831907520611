import React, {Fragment } from 'react';
import './Pricing.scss';

import PageNotFound from '../../../assets/icons/success.svg';
import { Link } from 'react-router-dom';
const PaymentComplete = ()=> {
  return (
      
    <Fragment>
      <div className="row h-100">
        <div className={`col-12 d-flex pt-5 flex-column justify-content-center 
    align-items-center mx-auto  `}>
          <img src={PageNotFound} 
            alt="page not found" className="noCourseImg"/>
          <h1 className="noDiscussionH1 mt-3">{ 'Payment Information'}</h1>
          <p className="mb-0 noDiscussionP mt-3 mb-5 "  >
            { 'You subscription was successful.'}
          </p>
      
          <Link to={'/'}>
            <button className="btn sme-btn-primary font-weight-600" style={{width: '15rem'}}>
              {'Goto Home'} 
            </button>
          </Link>
        </div>
      </div>
    </Fragment>
  );
};
export default PaymentComplete;
