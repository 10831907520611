import React, {Component} from "react";
import {withRouter} from "react-router-dom";
import {extractQueryParams} from "../../helpers/url";
import cookie from "../../helpers/cookie";
import {connect} from "react-redux";
import {isAuthenticated, logOut} from "../../helpers/utility";
import {getUser} from "../../store/actions/userActions";
import PageLoader from "../PageLoader/PageLoader";

class CheckAuth extends Component {
  state = {
    checkingAuthorization: true
  };

  constructor(props) {
    super(props);
    this.queryParams = extractQueryParams(this.props.location.search);
  }

  componentDidMount() {
    // cookie.put('accessToken', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNhMGZlYWNhLWZhODgtNGZlNC05NDQ4LTAwYWViNDhkYjNlZCIsImVtYWlsIjoianRhZDAwOUBnbWFpbC5jb20iLCJyb2xlX2lkIjoiYTk2YzI2ODAtYTUxZC00MzMzLWI2ODUtNDEyMmYwY2E1MWE3IiwiaWF0IjoxNjAwODcwNDI4LCJleHAiOjE2MDEwNDMyMjh9.wxPPyz1yQhCzvM5UAnxVSqf6trbZh14aLb65x_s1Kmc`);
    this.props.dispatch(getUser());
    // cookie.put('accessToken', `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImNhMGZlYWNhLWZhODgtNGZlNC05NDQ4LTAwYWViNDhkYjNlZCIsImVtYWlsIjoianRhZDAwOUBnbWFpbC5jb20iLCJyb2xlX2lkIjoiYTk2YzI2ODAtYTUxZC00MzMzLWI2ODUtNDEyMmYwY2E1MWE3IiwiaWF0IjoxNjAwODcwNDI4LCJleHAiOjE2MDEwNDMyMjh9.wxPPyz1yQhCzvM5UAnxVSqf6trbZh14aLb65x_s1Kmc`);
    // 
    if(this.queryParams.accessToken) {
      cookie.put('accessToken', this.queryParams.accessToken);
    }
    if(this.queryParams.refreshToken) {
      cookie.put('refreshToken', this.queryParams.refreshToken);
    }
    if(this.queryParams.accessToken || this.queryParams.refreshToken) {
      this.props.history.push("/");
    }
    
    if(!isAuthenticated()) {
      logOut(false, '/auth/sign-in');
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.checkingAuthorization) {
      if (this.props.user &&
        (!this.props.user.role ||
          (this.props.user.role &&  
             !['SYSTEM_ADMIN', 'CLIENT']
               .includes(this.props.user.role.category))
        )
      ) {
        logOut();
      }
      if (this.props.user && this.props.user.role &&
        ['SYSTEM_ADMIN', 'CLIENT']
          .includes(this.props.user.role.category)) {
        this.setState({checkingAuthorization: false});
      }
    }
  }

  render() {
   
    return isAuthenticated() ?
      this.state.checkingAuthorization ?
        (
          <div style={{minHeight: '70vh'}}className="min-content-height-header col-12 d-flex
          align-items-center justify-content-center">
            <PageLoader/>
          </div>
        )
        : (this.props.children)
      : (
        <div style={{minHeight: '50vh'}} className="row">
         
    
          <div className="col-12 mt-4 text-center">
            <PageLoader/>
          </div>
     
        </div>
      );
  }
}

const mapStateToProps = state => {
  return {
    user: state.userState.user
  };
};

export default withRouter(connect(mapStateToProps)(CheckAuth));
