import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
// import { calendarDetails } from '../../constants/general';
import './UpcomingDetails.scss';
import { connect } from 'react-redux';
import utilityHelper, { CurrencyFormatter } from "../../helpers/utility";
import { SET_PAGE_BACK_BUTTON, setCurrentMenu, SHOW_SNACKBAR } from '../../store/actions/coreActions';
import { smeFormatDate } from '../../helpers/utility';
import { LazyImage } from '../CourseItem/LazyLoadImage';

import { bookEvents, viewEvent } from '../../store/actions/eventsActions';
import LoadingCalendarDetails from './UILoaders/CalendarDetail';
import Loader from '../Loader/Loader';
import { attachAccessTokenToUrlQueryParams, 
  hardRedirect,
  updateQueryParams } from "../../helpers/url";
import { paystackPaymentLink } from "../../constants/general";
const UpcomingDetails = props => {
  const [requested, setRequested] = useState(false);
  const { dispatch, currentEvent, user } = props;
  
  const { event, booking, loading } = currentEvent  || {};
  let attendingEvent = event && event.attendees ? event.attendees.filter(x=> x.user_id === user.id).length : 0;
  useEffect(()=>{
    if(!requested){
      const url = window.location.pathname.split('/');
      if(!props.location.state){
        dispatch(viewEvent(url[2], user.id));
      }else {
        dispatch(viewEvent(props.location.state.event.id, user.id));
      }
     
      // attendingEvent = currentEvent.attendees.length();
      dispatch(setCurrentMenu('Calendar'));
      document
        .querySelector('#header #pageTitle .page-title')
        .innerText = 'Back to Upcoming Events';
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: true, location: '/calendar' } });  
      setRequested(true);
    }
  }, [requested, dispatch, currentEvent]);
  const handleAttendEventClick = ()=>{
    if(currentEvent.event.cost && !attendingEvent){
      onEnRollNowClicked();
    } else if(!currentEvent.event.cost) {
      dispatch(bookEvents(currentEvent.event.id));
    }else {
      dispatch({ type: SHOW_SNACKBAR , payload: { config: {message: 'You are already attending this event.'} } });
    }
   
  };
  const onEnRollNowClicked = () => {
      
    const { location } = window;
    const redirectUrl = `${(location || {}).protocol}//${(location || {}).host}/calendar/${currentEvent.event.title}`;
    let paymentUrl = updateQueryParams(paystackPaymentLink,
      { regId: currentEvent.event.id , redirectUrl, regType: 'event' });
  
    if (!utilityHelper.isAuthenticated()) {
      const authUrl = `/auth/sign-up?redirect=${encodeURIComponent(paymentUrl)}`;
      this.props.history.push(updateQueryParams(authUrl, { requireIdentity: 1 }));
    } else {
      paymentUrl = attachAccessTokenToUrlQueryParams(paymentUrl);
      hardRedirect(paymentUrl);
    }
  };
  {/* <NotFoundPage
    title="Opps!!!"
    subtitle="Sorry, it seems the event you selected doesn't exist or has been moved." 
    goto={`/calendar`}
    cta='Goto Calendar'/> */}
 
  return loading ? <LoadingCalendarDetails/> :
  
    (
      <Row>
      
        <Col md={8}>
          <Row>
           
            <Col md={12} className="p-0">
              <LazyImage src={event.image } 
                className="img img-fluid w-100 h-100" alt="event-banner"/>
            </Col>
            <Col md={12} className="p-0 mt-3 h-100 d-block d-lg-none">
              <Card style={{border:'none'}} className="eventAction">
                <Card.Body className="text-center ">
                  <img width="80" height="71" alt="avatar"
                    src={require('../../assets/img/calendar/cal-action.png')} 
                    className="mx-auto img img-fluid mb-2"/>
                  <b className="my-3">{ event.cost ? `Cost : ${ CurrencyFormatter(event.cost) }`:  '' }</b>
                  <b className="my-3">Date And Time</b>
      
                  <Card.Text className="text-center">
                    {smeFormatDate(event.start_date ).date}
                  </Card.Text>
                  <Card.Text className="text-center">
                    {smeFormatDate(event.start_date ).time}
                  </Card.Text>
                  { event.id && event.id.length && event.cost ? 
                    <Button disabled={booking} className={`sme-btn-primary ${attendingEvent ? 'focus no-shadow': ''} lift`} onClick={handleAttendEventClick} >
                      {booking  ? <Loader scale="0.3" /> : ''}
                      { !booking ? attendingEvent ? 'Attending' : `Register` : ' '}
                    </Button>
                 
                    : null }
                  { event.id && event.id.length && !event.cost ? 
                    <Button disabled={booking} className={`sme-btn-primary ${attendingEvent ? 'focus no-shadow': ''} lift`} onClick={handleAttendEventClick} >
                      {booking  ? <Loader scale="0.3" /> : ''}
                      { !booking ? attendingEvent ? 'Attending' : 'Attend this event' : ' '}
                    </Button>
                 
                    : null }
                </Card.Body>
              </Card>
            </Col>
  
            <Col md={12} className="p-0 mt-4">
              <Card style={{border:'none'}} className="eventCardDetails">
             
                <Card.Body className="py-5 px-4">
                  <Card.Title>
                    {event.title || ''}
                  
                  </Card.Title>
                  <hr className="d-block d-lg-none"/>
                  <br/>
                  <Card.Text dangerouslySetInnerHTML={{__html: event.description || ''}}></Card.Text>
              
                </Card.Body>
              </Card>
           
            </Col>
          </Row>
        </Col>
        <Col md={4} className="h-100 d-none d-lg-block">
          <Card style={{border:'none'}} className="eventAction">
            <Card.Body className="text-center ">
              <img width="80" height="71" alt="avatar"
                src={require('../../assets/img/calendar/cal-action.png')} 
                className="mx-auto img img-fluid mb-2"/>
              <b className="my-3">{ event.cost ? `Cost : ${ CurrencyFormatter(event.cost) }`:  '' }</b>
              <b className="my-3">Date And Time</b>

              <Card.Text className="text-center">
                {smeFormatDate(event.start_date ).date}
              </Card.Text>
              <Card.Text className="text-center">
                {smeFormatDate(event.start_date ).time}
              </Card.Text>
              { event.id && event.id.length && event.cost ? 
                <Button disabled={booking} className={`sme-btn-primary ${attendingEvent ? 'focus no-shadow': ''} lift`} onClick={handleAttendEventClick} >
                  {booking  ? <Loader scale="0.3" /> : ''}
                  { !booking ? attendingEvent ? 'Attending' : `Register` : ' '}
                </Button>
                 
                : null }
              { event.id && event.id.length && !event.cost ? 
                <Button disabled={booking} className={`sme-btn-primary ${attendingEvent ? 'focus no-shadow': ''} lift`} onClick={handleAttendEventClick} >
                  {booking  ? <Loader scale="0.3" /> : ''}
                  { !booking ? attendingEvent ? 'Attending' : 'Attend this event' : ' '}
                </Button>
                 
                : null }
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
};
const mapStateToProps = state => {
  return {
    event: state.eventState,
    currentEvent: state.eventState.currentEvent,
    user: state.userState.user
  };
};
export default connect(mapStateToProps)(UpcomingDetails);