import React, { Fragment, useEffect, useState} from 'react';
import { Col, Row , Button} from 'react-bootstrap';
import './Discussion.scss';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Pagination from '../Pagination/Pagination';
// import DiscussionItem from './SingleDiscussion';
import SingleDiscussion from '../Discussion/SingleDiscussion';
import NoDiscussionView from './noDiscussionView';

const Discussions = props => {
  
  const {
    colSize,
    dispatch,
    user,
    discussionList,
    addHeader,
    removeheaderBorder,
    viewCommunity,
    showPagination,
    className
  } = props;
  const [discussions, setDiscussions] = useState([]);

  useEffect(() => {
    setDiscussions((discussionList || {}).discussions);
  }, [discussionList]);
  
  const cardHeader = (
    <Row className="mt-4">
      <Col md={12} className="d-flex justify-content-between 
      align-items-center discussion-title-bar">
        <span>Latest Discussions</span>
        <Link to={`/community/`} className="allEventLink">
          View Community
        </Link>
      </Col>
    </Row>
  );

  return discussions && discussions.length ? (
    <Fragment>
      <Col md={colSize || 8} sm={12} className={`${className}`}>
        <Fragment>
          { addHeader ? cardHeader : null }
          
          <Row className="mt-4">
            { (discussions || [null, null]).map((discussion, i) => (
              <SingleDiscussion 
                dispatch={dispatch} 
                key={i} 
                user={user} 
                discussion={discussion} 
                index={i} 
                removeheaderBorder={removeheaderBorder}
                addHeader={addHeader} 
                category={ (discussion || {}).title || '' } 
              />
            ))
            }
            {
              viewCommunity ? 
                <Col>
                  <Link to={{ pathname: '/community' }}
                    style={{textDecoration:'none'}}>
                    <Button  className="sme-btn-primary p-3 btn-block no-border">
                    View Community
                    </Button>
                  </Link>
                </Col>
                : null
            }
            {
              showPagination && discussions.length ? <Col md={12} >
                <Pagination list={discussionList}
                  updateWho="discussions" 
                  title="See more Discussions" 
                />
              </Col>
                : null 
            }
          </Row>
        </Fragment>
      </Col>
    
    </Fragment>
  ): 
    <Col md={colSize || 8} sm={12} className="mt-4">
      <Row className="mt-4">
         
      </Row>
      <Row className="mt-4">
        <NoDiscussionView/>
      </Row>
    </Col>;
};


const mapStateToProps = state => {
  return {
    user: state.userState.user
  };
};
  

export default connect(mapStateToProps) (Discussions);
