import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { getListFromResponse } from "../../helpers/utility";
import { SHOW_SNACKBAR } from './coreActions';
import { updateQueryParams } from "../../helpers/url";
export const REPLY_DISCUSSION = "DISCUSSION/REPLY";
export const LIKE_DISCUSSION = "DISCUSSION/LIKE";
export const LOAD_DISCUSSION = "DISCUSSION/LOAD";
export const LOAD_CATEGORIES = "DISCUSSION/LOAD_CATEGORIES";
export const LOAD_TOPICS = 'DISCUSSION/LOAD_TOPICS';
export const LOAD_DISCUSSION_TOPICS = 'DISCUSSION/LOAD_DISCUSSION_TOPICS';
export const PREPEND_DISCUSSION  = 'DISCUSSION/PREPEND_DISCUSSION_TOPICS';
export const LOADING_DISCUSSION = 'DISCUSSION/LOADING';
export const APPEND_DISCUSSION = 'DISCUSSION/ADD_DISCUSSION';
export const loadDiscussions = () => {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_ALL_DISCUSSIONS)
      .then(response => {
        dispatch({
          type: LOAD_DISCUSSION, 
          payload: {discussions:  getListFromResponse(response, 'discussions'), loading: false}
        });
      });
  };
};
export const getTopics = () => {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_ALL_DISCUSSIONS_TOPICS)
      .then(response => {
        dispatch({
          type: LOAD_TOPICS, 
          payload: {topics:  response.data.data}
        });
      });
  };
};

/**
 * Load discusion categorires for sidebar
 */
export const getDiscussionTopics = () => {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_DISCUSSION_TOPICS)
      .then(response => {
        dispatch({
          type: LOAD_DISCUSSION_TOPICS, 
          payload: {discussion_topics:  response.data.data}
        });
      });
  };
};
/**
 * Load discusion categorires for sidebar base on topics this user has taken
 */
export const getDiscussionOnUserTakenTopics = (userId) => {
  console.log("getDiscussionOnUserTakenTopics: ", userId)
  return function(dispatch) {
    return http.get(updateQueryParams(apiRoutes.LOAD_DISCUSSION_TOPICS, {user_id: userId}))
      .then(response => {
        dispatch({
          type: LOAD_DISCUSSION_TOPICS, 
          payload: {discussion_topics:  response.data.data}
        });
      });
  };
};

export const loadCategories = () => {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_CATEGORIES)
      .then(response => {
        dispatch({
          type: LOAD_CATEGORIES, 
          payload: {categories:  response.data.data}
        });
      });
  };
};
/**
 * Like Discussions
 * @param {*} currentPage 
 */
export const likeDiscussion = params => {
  return function(dispatch) {
    return http.put(apiRoutes.LIKE_DISCUSSION.replace('@id', params.id), params)
      .then(response => {
        dispatch({ type: LIKE_DISCUSSION, payload: {discussionsWithReaction: response.data.data, lastAction: response.data.message}});
        dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: response.data.message} } });
        // disp/atch({ type: MARK_AS_LIKED, payload: { config: {message: response.data.message} } });
      });
  };
};

/**
 * Reply Discussion
 * @param {*} currentPage 
 */
export const replyDiscussion = () => {
  return function(dispatch) {
    return http.post(apiRoutes.COMMENT_ON_DISCUSSION)
      .then(response => {
        dispatch({type: REPLY_DISCUSSION, payload: {discussions: response.data.data}});
      });
  };
};
export const loadCourseDiscussions = params => {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_COURSE_DISCUSSIONS.replace('@id', params))
      .then(response => {
        dispatch({
          type: LOAD_DISCUSSION, 
          payload: {discussions:  getListFromResponse(response, 'discussions'), loading: false}
        });
      });
  };
};
export const loadingDiscussion = () => {
  return function(dispatch) {
    dispatch({
      type: LOADING_DISCUSSION, 
      payload: { loading: true}
    });
  };
};

