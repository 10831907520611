import React, { Fragment, useEffect, useState } from 'react';
import { Row, Col, Button, Modal } from 'react-bootstrap';
import studentHomeStyles from "../../containers/pages/StudentHome/StudentHome.module.scss";
import PageTitle from '../PageTitle/PageTitle';
import TabTitle from '../PageTitle/TabTitle';
import MTabTitle from '../PageTitle/MTabTitle';
import { connect, useSelector } from 'react-redux';
import { DiscussionShimmer } from '../../components/Shimmer/shimmerArticle';
import {
  loadDiscussions, loadCategories, getTopics, getDiscussionTopics, 
  loadingDiscussion, loadCourseDiscussions
} from "../../store/actions/discussionActions";

import http from '../../helpers/http';
import apiRoutes from '../../constants/apiRoutes';
import {
  setCurrentMenu, setSectionTitle, SHOW_SNACKBAR
} from "../../store/actions/coreActions";
import './Community.scss';

import Loader from  '../Loader/Loader.js';
import avatar from '../../assets/img/header/avatar.png';

import StartDiscussionModal from './StartDiscussionModal';
import Discussions from '../DicussionCard/Discussions';
import CommunitySidebar from './CommunitySidebar';
import StartDiscussionButton from './startDiscussionButton';


const Community = props => {
  const userData = useSelector(state => state.userState.user);

  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatch, discussions, discussionTopics, discussionsLoading } = props;
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!requested) {
      dispatch(loadDiscussions());
      dispatch(getDiscussionTopics()); // for sidebar
      dispatch(setCurrentMenu('community'));
      dispatch(setSectionTitle({
        title: 'All Discussion ',
        hasHr: false
      }));
      dispatch(getTopics());
      dispatch(loadCategories());
      setRequested(true);
    }
  }, [requested, dispatch]);

  useEffect(() => {
    if (discussions) {
      dispatch(setSectionTitle(
        {
          title: `All Discussion “${discussions.total} Questions”`,
          hasHr: false
        }));
    }
  }, [discussions, dispatch]);
  const handleClose = () => setShowModal(false);
  /**
   * Post a new discussion 
   */
  const postDiscussion = ()=> {
    const discussionText = document.getElementById('c-discussionText').value;
    const discussionCourse = document.getElementById('c-discussionCategory');
    if(discussionText.length > 0 && 
      discussionCourse.options[discussionCourse.selectedIndex].value.length > 0){
      setLoading(true);
      http.post(apiRoutes.ADD_COURSE_DISCUSSION, 
        {
          course_id: discussionCourse.options[discussionCourse.selectedIndex].value,
          content: discussionText, 
          title:  discussionCourse.options[discussionCourse.selectedIndex].text
        })
        .then(response => {
          // console.log("Response from server", response.data.data);
          setLoading(false);
          // This is a test of all that is normally forbidden and not allowed
          dispatch(loadingDiscussion());
          dispatch(loadCourseDiscussions(response.data.data.results[0].course_id));
          setShowModal(false);
          dispatch({ type: SHOW_SNACKBAR, payload: {
            config: {message: response.data.message}
          } });
        });
    }else{
      dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: 'Discussion topic or Discussion cannot be empty'}
      } });
    }
  };
  /**
   * Open the discussion modal
   */
  const startDiscussionModal = () => {
    let options = [];
    if(props.topics && props.topics.length){
      props.topics.forEach((topic, index)=>{
        options.push(<option key={index} value={topic.module_topic.course_id}>{topic.title}</option>);
      });
    }  
    return (<Modal
      show={showModal}
      onHide={handleClose}
      dialogClassName={` modal-90w  discussionModal `}
      aria-labelledby="example-custom-modal-styling-title"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className={`classTitle`}>Post Something</Modal.Title>
       
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between ">
        
          <img 
            src={userData && userData.image ? userData.image : avatar} 
            alt="useerlogo" className="rounded-circle" style={{height:'36px', width:'36px'}}/>
        
          <textarea id="c-discussionText" className={`discussionTextBox ml-2 mr-2`} placeholder="What's on your mind"></textarea>
          
  
          { loading ? <Loader color="#0062FF" scale={props.scale} type={props.type || "ripple"}/> : '' } 
        </div>
        <input type="text" id="discussionTags"  className={`discussionTagBox ml-2 mr-2 d-none`} placeholder="Enter Tags"/>
      </Modal.Body>
      <Modal.Footer >
        <div className="d-flex justify-content-between w-100">
          
          <div className="select-holder p-0 pl-3">
            <span>Topic:</span>
            <select id="c-discussionCategory">
              <option value=''>Choose...</option>
              { options }
            </select>
          </div>
          <Button className={`sme-btn-primary postButton`} onClick={postDiscussion}>
            Share Post
          </Button>
        </div>
       
      </Modal.Footer>
    </Modal>);
  };


  return (
    <Fragment>
      {startDiscussionModal()}
      <div className={`col-12 pb-5  p-0 min-content-height`}>
        <div className={`col-12 ${studentHomeStyles.Header} p-0`}>
          <div className={`${studentHomeStyles.HeaderContent} col-12 p-0`} >
            <Row className="w-100 m-0 mt-4">
              <Col md={8} className=" d-none d-lg-block d-flex flex-columns align-items-center pr-4">
               
                
                <div className="d-flex flex-columns align-items-center justify-content-between" >
                  <div>{ window.innerWidth < 1024 ? '' : <PageTitle title="All Discussion" hasHr={false} />}</div>
                  <div className=" d-none d-lg-block">
                    <div className="d-flex flex-columns">
                      <TabTitle title="Latest" hasHr={true} isTab={true} classStyle="mr-5  d-none d-lg-block" />
                      <TabTitle title="Trending" hasHr={false} isTab={true} />
                    </div>
                  </div>
                </div>
                {/* <StartDisicussionSmallScreen action={() => setShowModal(true)}/> */}
              </Col>
              <Col md={4} className="pr-0">
                <StartDiscussionButton action={() => setShowModal(true)}/>
              </Col>
            </Row>
          </div>
          {/* mobile screen */}
          <Row>
         
            <Col sm={12} className="d-block d-lg-none">
              <StartDiscussionModal />
            </Col>

            <Col sm={12} className="p-0">
              <div className={`mobileStrip d-block d-lg-none px-4 mb-2`} style={{height:'45px'}}>
                <div className="d-flex justify-content-around  " >
                  <MTabTitle title="Latest" hasHr={true} isTab={true} />
                  <MTabTitle title="Trending" hasHr={false} isTab={true} />
                 
                </div>
              </div>
            </Col>
          </Row>
          {/* end mobile screen */}
        </div>
        <div className={`col-12  p-0`}>
          <Row>
            {  
              !discussionsLoading ? 
                <Discussions 
                  dispatch={dispatch} 
                  addHeader={false}  
                  discussionList={discussions} 
                  showPagination={true} /> 
                :
                <Col md={8} sm={12} className="mt-4">
                  <DiscussionShimmer maxCards="2" />
                </Col> 
            }
            <CommunitySidebar
              dispatch={dispatch}
              handleClose={() => setShowModal(true)}
              discussionTopics={discussionTopics}
            />
          </Row>

        </div>
      </div>
    </Fragment>);
};

const mapStateToProps = state => {
  return {
    discussionsLoading: state.discussionState.discussionLoading,
    discussions: state.discussionState.discussionList,
    categories: state.discussionState.categories,
    topics: state.discussionState.topics,
    discussionTopics: state.discussionState.discussion_topics
  };
};

export default connect(mapStateToProps, null)(Community);
