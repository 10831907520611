import React from 'react';
import { Card, Col, Button } from 'react-bootstrap';
import '../UpcomingEvents.scss';
import '../../../assets/scss/custom-buttons.scss';
import { LazyImage } from '../../CourseItem/LazyLoadImage';
import { smeFormatDate } from '../../../helpers/utility';
import { Link } from 'react-router-dom';

const EventCard = props => {
  
  return (
    <Col md={12} key={props.index}>
      <Card className="mt-4  radius20 border-0 single-event-card lift ">
        <Card.Header className="no-border radius-top-20">
          <LazyImage
            height="97"
            width="250"
            src={props.event.image}
            alt={props.event.title} className={'w-100  card-img-top'}/>
        </Card.Header> 
        
        <Card.Body className="p-0 px-3" >
          <p className="max-line max-line-2"><b>{props.event.title}</b></p>
         
          
        </Card.Body>
        <Card.Footer className="no-border radius-bottom-20">
          <div className=" w-100">
           
            <div className="d-flex flex-columns justify-content-between align-items-center">
              { props.event.slug && props.event.slug.length ? 
                <Link to={`calendar/${props.event.slug}`} target="blank">
                  <Button variant="link" 
                    className={`sme-btn-primary  eventButton`} style={{textDecoration: 'none'}} > 
        Attend
                  </Button>
                </Link>
                : null }
              <p>
                <img src={require('../../../assets/icons/event-calendar.svg')} alt="cal"/>
                <span className="ml-2 eventTime ">{smeFormatDate(props.event.start_date ).date} </span>
              </p>
              
            </div>
          </div> 
        </Card.Footer>
      </Card>
    </Col>
  );
};
export default EventCard;