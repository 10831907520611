import React from 'react';
import './CustomSelect.scss';
import { extractQueryParams, insertUrlParam, updateQueryParams } from '../../helpers/url';
import { getUserSubscriptions, loadingSub, getCategory } from '../../store/actions/userActions';
import {  getCourseList, loadingCourse, 
  SET_COURSE_CATEGORIES } from '../../store/actions/coreActions';
import { connect, useSelector } from "react-redux";
const CustomSelect =  props => {
  const categories = useSelector(state => state.coreState.categories);
  
  const filterByCatgory = (event) => {
    const location = updateQueryParams(event.target.options[event.target.selectedIndex].getAttribute('to'));
    const newurl = insertUrlParam('category', location);
    const queryParams = extractQueryParams(newurl);
    // console.log({newurl, queryParams, location});
    const ctg = categories.find(c => {
      return c.name === location;
    });
    if(queryParams.category === 'all'){
      props.clearFilter();
    } else {
     
      props.loadUserCourse();
      if(ctg){
        props.loadCategories({
          id: ctg.id
        });
      } else {
        props.loadCategories({});
      }
      // this.props.getUserSubscriptions(queryParams);
      
      
    }
    
  };
  
  const generateCategoriesOption = ()=>{
    const cats = [];
    cats.push(<option key="all" to="all">All</option>);
    if(categories){
      categories.forEach((c, index) => (
        cats.push(<option key={index} to={c.name.trim()}>{c.name}</option>)
      ));
    }
  
    return cats;
  };

  return (
    <div className="select-holder p-0 pl-3">
      <span>Sort By:</span>
      <select onChange={filterByCatgory}>
        {generateCategoriesOption()}
      </select>
    </div>
     
  
  );
};
const mapDispatchToProps = dispatch => {
  return {
    loadCategories: params => dispatch(getCategory(params)),
    loadingCourses: () => dispatch(loadingCourse()),
    loadUserCourse: () => dispatch(loadingSub()),
    loadCourses: params => dispatch(getCourseList(params)),
    getUserSubscriptions: params => dispatch(getUserSubscriptions(params)),
    clearFilter: () => dispatch({
      type: SET_COURSE_CATEGORIES,
      payload: {categories : []}
    })
  };
};
export default connect(null,mapDispatchToProps) (CustomSelect);