import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import './ProfileEdit.scss';
import { connect } from "react-redux";
import { SET_USER } from '../../store/actions/userActions';
import { SHOW_SNACKBAR } from '../../store/actions/coreActions';
import Loader from '../Loader/Loader';
import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import {nigerianStates} from '../../constants/general';
import {disableForm} from '../../helpers/utility';

const ProfileCardEdit = props => {

  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const toggleForm = e => {
    const text = !disabled ? 'Edit Profile' : 'Cancel';
    e.target.innerText= text;
    document.getElementById('name').focus();
    setDisabled(!disabled);
    disableForm('form.profileEdit  .form-control', disabled);
  };

  const saveProfileInformation = (event )=>{
    event.preventDefault();
    if(isLoading){
      return;
    }
    const data = new FormData(event.target);
    const fullname = data.get('name').split(' ');
    data.append('first_name', fullname[0]);
    data.append('last_name', fullname[1]);
    setIsLoading(true);
    http.put(apiRoutes.USER_EDIT, data).then(response => {
      setIsLoading(false);
      setDisabled(!disabled);
      props.dispatch({
        type: SET_USER,
        payload: {user: response.data.data}
      });
      props.dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: response.data.message}
      } });
    });

  };
  return (
    <Card className="border-0 mt-4">
      <Card.Body>
        <Card.Title className="profile mb-4 d-flex flex-columns justify-content-between align-items-center">
          <span>Basic Information</span>
          <span><Button variant="link" style={{textDecoration: 'none', color:'#111'}} className="no-lift sme-btn-grey" onClick={toggleForm}> Edit Profile </Button></span>
        </Card.Title>
        <Form onSubmit={saveProfileInformation} className="profileEdit">
          <Form.Group controlId="name">
            <Form.Label>Name</Form.Label>
            <Form.Control  disabled={disabled} id="name" name="name" type="text" placeholder="Enter firstname and last name" defaultValue={props.student && props.student.first_name.length ? `${props.student.first_name} ${props.student.last_name}` : ''} />
          </Form.Group>
          <Form.Group controlId="email">
            <Form.Label>Email (Required)</Form.Label>
            <Form.Control disabled={disabled} name="email" required type="email" placeholder="Enter email" defaultValue={props.student && props.student.email.length ? `${props.student.email} ` : ''} />
          </Form.Group>
          <Form.Group controlId="phone">
            <Form.Label>Phone (Optional)</Form.Label>
            <Form.Control disabled={disabled} name="phone" type="text" placeholder="Enter phone" defaultValue={props.student && props.student.phone ? `${props.student.phone} ` : ''}  />
          </Form.Group>
          <Form.Group controlId="formBAddress">
            <Form.Label>Business Address (Required)</Form.Label>
            <textarea disabled={disabled} name="address" className="form-control" cols="20" rows="5" placeholder="Enter Business Address description" defaultValue={props.student && props.student.address  ? props.student.address : ''}></textarea>
          </Form.Group>
          <Form.Group controlId="state">
            <Form.Label>State </Form.Label>
            {
              disabled ? 
                <div className="profileLabel rounded px-3">{(props.student && props.student.state) ? props.student.state: ''} </div>
                :
                <Form.Control className="form-select" size="sm" disabled={disabled} as="select" name="state" defaultValue={props.student && props.student.state ? `${props.student.state}` : ''}>
                  <option>Select</option>
                  {
                    nigerianStates.map((state, index) => {
                      return (<option key={index}>{state}</option>);
                    })
                  }
                </Form.Control>
            }
          </Form.Group>
          <Form.Group controlId="country">
            <Form.Label>Country </Form.Label>
            {
              disabled ? 
                <div className="profileLabel rounded px-3">{(props.student && props.student.country) ? props.student.country: ''} </div>
                :
                <Form.Control disabled={disabled} className="form-select" name="country" as="select" defaultValue={props.student && props.student.country ? `${props.student.country}` : 'Nigeria'}>
                  <option></option>
                  <option>Nigeria</option>
                </Form.Control>
            }
          </Form.Group>
         
          <Button disabled={disabled} className="sme-btn-primary px-4  py-2 mt-5 d-flex align-items-center " type="submit">
                      Update My Profile
            <span className="ml-1">{isLoading ? <Loader scale="0.3" /> : '' }</span>
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};
export default connect()(ProfileCardEdit);
