import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { SHOW_SNACKBAR, 
  CLEAR_ALERT, 
  showSnackbar, 
  SET_COURSE_CATEGORIES } from "./coreActions";
import { attachAccessTokenToUrlQueryParams, 
  replaceDynamics, 
  updateQueryParams } from "../../helpers/url";
import { NETWORK_ERROR_MESSAGE, ACTIVATION_LINK } from "../../constants/general";
import { getListFromResponse, logOut } from "../../helpers/utility";

export const SET_USER = "USER/SET_USER";
export const EDIT_USER = "USER/EDIT_USER";
export const SET_COURSE_LIST = "USER/SET_COURSE_LIST";
export const SET_CURRENT_COURSE = "USER/SET_CURRENT_COURSE";
export const FETCH_USER_DISCUSSIONS = "USERS/GET_DISCUSSIONS";
export const UPDATE_USER_BUSINESS = "USERS/UPDATE_USER_BUSINESS";
export const RESEND_ACTIVATION = "USERS/RESEND_ACTIVATION";
export const LOADING_COURSE = "USER/LOADING_COURSE";
export const UPDATE_SUB = "USER/UPDATE_SUB";
export const COMPLETED_COURSE_COUNT = "USER/COMPLETED_COURSES";
export const PENDING_COURSE_COUNT = "USER/PENDING_COURSES";
export const getCompletedCoursesCount = () => {
  return function(dispatch) {
    
    return http.get(updateQueryParams(apiRoutes.GET_COMPLETED_COURSE_COUNT, { completed: true })).then(response => {
      if(response.data.code === 200){
        dispatch({type: COMPLETED_COURSE_COUNT, payload: {completedCourseCount: response.data.data.count}});
      } else if(response.data.code === 404){
        dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: 'Could not fetch completed course count.'}
        } });
      }
    }).catch(()=>dispatch({ type: SHOW_SNACKBAR, payload: {
      config: {message: 'Could not fetch completed course count.'}
    } }));
  };
};
export const getPendingCoursesCount = () => {
  return function(dispatch) {
    return http.get(apiRoutes.GET_PENDING_COURSE_COUNT).then(response => {
      if(response.data.code === 200){
        dispatch({type: PENDING_COURSE_COUNT, payload: {pendingCourseCount: response.data.data.count}});
      } else if(response.data.code === 404){
        dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: 'Could not fetch pending course count.'}
        } });
      }
    }).catch(()=>dispatch({ type: SHOW_SNACKBAR, payload: {
      config: {message: 'Could not fetch pending course count.'}
    } }));
  };
};
export const loadingSub = () => {
  return function(dispatch) {
    dispatch({
      type: LOADING_COURSE, 
      payload: { loadingUserCourse: true}
    });
  };
};
export function getUser() {
  return function(dispatch) {
    return http.get(apiRoutes.USER_PROFILE).then(response => {
      if(response.data.code === 200){
        dispatch({type: SET_USER, payload: {user: response.data.data}});
      }else if(response.data.code === 404){
        logOut(true,'/auth/sign-in');
      }
    });
  };
}
export function updateUser(params) {
  return function(dispatch) {
    return http.put(apiRoutes.USER_EDIT, params).then(response => {
      dispatch({type: SET_USER, payload: {user: response.data.data}});
    });
  };
}
export function fetchUserDiscussions() {
  return function(dispatch) {
    return http.get(apiRoutes.LOAD_USER_DISCUSSIONS)
      .then(response => {
        dispatch({type: FETCH_USER_DISCUSSIONS, payload: {discussions: response.data.data}});
      });
  };
}
export function getUserSubscriptions(params = '') {
  return function(dispatch) {
    let link = apiRoutes.USER_SUBSCRIPTION;
    if(params){
      link = updateQueryParams(link, params);
    }
    return http.get(link).then(response => {
      if(response.data.data.total){
        dispatch({
          type: SET_COURSE_LIST, payload: 
        {
          courseList: getListFromResponse(response, 'courses'),
          loadingUserCourse : false
        }
        });
      } else if(!response.data.data.total && !params){
        dispatch({
          type: LOADING_COURSE, 
          payload: { loadingUserCourse: false}
        });
      } else if(!response.data.data.total && params) {
        dispatch({
          type: LOADING_COURSE, 
          payload: { loadingUserCourse: false}
        });
        dispatch({ type: SHOW_SNACKBAR, payload: {
          config: {message: 'No Courses found, for this filter criteria.'}
        } });
      }
     
    })
      .catch(e => {
        const { data } = (e.response || {});
        if(!data) {
          dispatch({ type: SHOW_SNACKBAR, payload: {
            config: {message: NETWORK_ERROR_MESSAGE}
          } });
        } else if(data.code !== 404) {
          dispatch({ type: SHOW_SNACKBAR, payload: {config: {message: data.message}} });
        } else if(data.code === 404){
          dispatch({ type: SHOW_SNACKBAR, payload: {
            config: {message: "No Subscription found"}
          } });
        }
        //TODO: MORE RESEARCH
        dispatch({type: SET_COURSE_LIST, payload: {courseList: {courses: []}}});
      });
  };
}
export const resendWelcomeMail = ()=>{
  return dispatch => {
    http.post(updateQueryParams(apiRoutes.USER_RESEND_EMAIL_VERIFICATION, 
      {
        redirectUrl: ACTIVATION_LINK
      })).then(
      response => {
        dispatch({
          type: CLEAR_ALERT});
        dispatch({
          type: SHOW_SNACKBAR,
          payload: {config: {message: response.data.message, verticalAlign: 'bottom'}}
        });
      });
  };
};
export function getSubscriptionDetail(id) {
  return function(dispatch) {
    const url = replaceDynamics(apiRoutes.USER_SUBSCRIPTION_DETAILS, {id});
    return http.get(url).then(response => {
      const sub = response.data.data;
      sub.playlist = [];
      sub.intro = [];
      if(sub && sub.course && sub.course.modules) {
        const { modules } = sub.course;
        const introduction = { about: sub.course.description };
        introduction.modules = [];
        modules.forEach(m => {
          introduction.modules.push({
            topic: m.title,
            description: m.description,
            id: m.id
          });
          
          m.module_topic.forEach(t => {
            sub.playlist.push({
              courseId: sub.course.id,
              moduleId: m.id,
              topicId: t.id,
              resourceUrl: t.resource,
              title: t.title,
              currentTime: (t.topicProgress || {}).currentTime
            });
          });
        });
        sub.intro.push(introduction);
      }
     
      dispatch({ type: SET_CURRENT_COURSE, payload: { currentSubscription: sub } });
    }).catch(() => {
      dispatch({ type: SET_CURRENT_COURSE, payload: { currentSubscription: {} } });
    });
  };
}

export function flagTopicForReview(params) {
  return function(dispatch) {
    return http.post(apiRoutes.FLAG_A_TOPIC.replace('@topicId', params.topicId), params).then(response => {
      if(response.data.code === 201){
        // console.log(response.data.message);
        dispatch({ type: SHOW_SNACKBAR, payload: {changed: true, config: {message: response.data.message} } });
      }
    
     
    });
  };
  
}
export const getCategory = (params) => {
  return dispatch => {
    let url = apiRoutes.CATEGORY_LIST;
    if(params){
      url = apiRoutes.CATEGORY_LIST+params.id;
    }
    url = attachAccessTokenToUrlQueryParams(url);
    return http.get(url).then(
      response => {
        dispatch({
          type: LOADING_COURSE, 
          payload: { loadingCourse: false, loadingUserCourse: false}
         
        });
        // if(response.data.data.total){
        dispatch({
          type: SET_COURSE_CATEGORIES,
          payload: {categories : [response.data.data]}
        });
        
        // }else if(!response.data.data.total){
        //   dispatch(showSnackbar({ message: `No Courses found, for this filter criteria.` }));
        // }
        
      },
      error => {
        if(!error.response) {
          dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: NETWORK_ERROR_MESSAGE} } });
        } else {
          dispatch(showSnackbar({ message: `Error getting category list: ${error.message}` }));
        }
        
      }
    );
  };
};
