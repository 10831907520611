import React from "react";
import moment from 'moment';
import './CalandarMobile.scss';
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const SelectList = (props) => {
  let popup = props.data.map((data) => {
    return (
      <div key={data}>
        <button className="convertButtonToLink no-border"  onClick={(e)=> {props.onSelectChange(e, data);}}>
          {data}
        </button>
       
      </div>
    );
  });

  return (
    <div className="month-popup">
      {popup}
    </div>
  );
};
export default class CalendarWidgetMobile extends React.Component {
  weekdayshort = moment.weekdaysShort();
  state = {
    dateContext: moment(),
    today: moment(),
    showMonthPopup: false,
    showYearPopup: false,
    selectedDay: null
  }
  componentDidMount() {
    
  }
  componentDidUpdate(prevProps, prevState) {
    const currentDateElement = document.querySelector('.cd');
    if (currentDateElement) {
      currentDateElement.parentElement.scrollIntoView({ behavior: 'smooth' });
    }

  }
  weekdays = moment.weekdays(); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
  weekdaysShort = moment.weekdaysShort(); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  months = moment.months();

  year = () => {
    return this.state.dateContext.format("Y");
  }
  month = () => {
    return this.state.dateContext.format("MMMM");
  }

  currentDayLabel = (date) => {
    const dt = moment(date, "YYYY-MM-DD HH:mm:ss");
    return dt.format('ddd');
  }
  daysInMonth = () => {
    return this.state.dateContext.daysInMonth();
  }
  currentDate = () => {
    return this.state.dateContext.format("MM");
  }
  currentDay = () => {
    return this.state.dateContext.format("D");
  }

  firstDayOfMonth = () => {
    let dateContext = this.state.dateContext;
    let firstDay = moment(dateContext).startOf('month').format('d'); // Day of week 0...1..5...6
    return firstDay;
  }

  setMonth = (month) => {
    let monthNo = this.months.indexOf(month);
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).set("month", monthNo);
    this.setState({
      dateContext: dateContext
    });
  }

  nextMonth = () => {
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).add(1, "month");
    this.setState({
      dateContext: dateContext
    });
    this.props.onNextMonth && this.props.onNextMonth();
  }

  prevMonth = () => {
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).subtract(1, "month");
    this.setState({
      dateContext: dateContext
    });
    this.props.onPrevMonth && this.props.onPrevMonth();
  }

  onSelectChange = (e, data) => {
    this.setMonth(data);
    this.props.onMonthChange && this.props.onMonthChange();

  }
  

  onChangeMonth = (e, month) => {
    this.setState({
      showMonthPopup: !this.state.showMonthPopup
    });
  }

  MonthNav = () => {
    return (
      <span className="label-month mr-2"
        onClick={(e) => { this.onChangeMonth(e, this.month()); }}>
        {this.month()}
        {this.state.showMonthPopup &&
          <SelectList data={this.months} onSelectChange={ this.onSelectChange }/>
        }
      </span>
    );
  }

  showYearEditor = () => {
    this.setState({
      showYearNav: true
    });
  }

  setYear = (year) => {
    let dateContext = Object.assign({}, this.state.dateContext);
    dateContext = moment(dateContext).set("year", year);
    this.setState({
      dateContext: dateContext
    });
  }
  onYearChange = (e) => {
    this.setYear(e.target.value);
    this.props.onYearChange && this.props.onYearChange(e, e.target.value);
  }

  onKeyUpYear = (e) => {
    if (e.which === 13 || e.which === 27) {
      this.setYear(e.target.value);
      this.setState({
        showYearNav: false
      });
    }
  }

  YearNav = () => {
    return (
      this.state.showYearNav ?
        <input
          defaultValue={this.year()}
          className="editor-year"
          ref={(yearInput) => { this.yearInput = yearInput; }}
          onKeyUp={(e) => this.onKeyUpYear(e)}
          onChange={(e) => this.onYearChange(e)}
          type="number"
          placeholder="year" />
        :
        <span
          className="label-year mr-3"
          onDoubleClick={(e) => { this.showYearEditor(); }}>
          {this.year()}
        </span>
    );
  }

  onDayClick = (e, day) => {
    this.setState({
      selectedDay: day
    }, () => {
      document.querySelectorAll('.eventDataGroup').forEach(element=>{
        if(!element.classList.contains('d-none')){
          element.classList.add('d-none');
        }
      });
      document.querySelector(`.eventDetails-${this.state.selectedDay}`).classList.toggle('d-none');
    });

    this.props.onDayClick && this.props.onDayClick(e, day);
  }

  /**
   * Scroll the strip lleft or right
   * @param {DOMEVENT} event 
   */
  slideStripLeft = (event)=> {
    const {type} = event.target.dataset;
    const parent = document.querySelector('.calendarStrip');
    if(type === 'forward'){
      parent.scroll({ left: parent.scrollLeft + 400, behavior: 'smooth' });
    }else{
      parent.scroll({ left: parent.scrollLeft - 400, behavior: 'smooth' });
    }
  }
 
  render() {

    let daysInMonth = [];
    let eventsInMonth = [];
    if (!this.props.events.allEvents.loading) {

      for (let d = 1; d <= this.daysInMonth(); d++) {
        let className = (d === parseInt(this.currentDay()) ? "day current-day" : "day");
        let currentDayDetails = (d === parseInt(this.currentDay()) ? "" : "d-none");
        let currentDay = (d === parseInt(this.currentDay()) ? "current-day cd" : "");
        let selectedClass = (d === parseInt(this.state.selectedDay) ? " selected-day " : "");
        let event = [];
        event = this.props.events.allEvents.events.map((item, i) => {
          const formatEventDate = moment(item.start_date).format(moment.HTML5_FMT.DATE);
          const calendarDate = moment(`${this.state.dateContext.format("Y")}-${this.state.dateContext.format("M")}-${d}`, 'YYYY-MM-DDTHH:mm:ss.SSSSZ').format(moment.HTML5_FMT.DATE);
          if (moment(calendarDate).isSame(formatEventDate)) {
            return (<div className={`eventData ${calendarDate === 'Invalid date' ? 'invalidDate' : ''} px-3 mb-2 `} key={`eventData-${i}`}>
              <div className="py-2">
                <Link to={{
                  pathname: `/calendar/${item.slug}`,
                  state: { event: { ...item } }
                }} key={i}>{item.title}</Link>
                <p className="mt-3" >Start Time:  {moment(item.start_date).format(moment.HTML5_FMT.TIME)}</p>
                 
              </div>

              <img width="50" height="50" alt="calendar"
                src={item.image || require('../../assets/img/calendar/cal-action.png')} />
            </div>);
          }
          return '';
        });
        daysInMonth.push(
          <div className={`${className} ${selectedClass}`} id={d * 2} key={d * 2} onClick={this.showDetails}>
            <div className={`dates mb-2 text-center eventDate-${d * 2}`} >
              <p className={`${currentDay} w-100 `} onClick={(e) => { this.onDayClick(e, d); }}>{d}</p>
              <p className={`${currentDay}`}
                onClick={(e) => { this.onDayClick(e, d); }}>
                {this.currentDayLabel(`${this.year()}-${this.currentDate()}-${d}`)}
              </p>
            </div>
          </div>
        );
        eventsInMonth.push(<div
          className={`eventDataGroup  h-100 eventDetails-${d} p-4 ${currentDayDetails}`}
          key={`eventDetails-${d * 2}`}>
          <p className="text-bold px-3" style={{ fontSize: '1.5rem' }}>{this.state.dateContext.format("dddd")}, {this.state.dateContext.format("MMMM")} {d}</p>
          {event.length ? <p className="no-event px-3"> No Events</p> : ''}
          {event}
        </div>);
      }
    }


    var totalSlots = [ ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if ((i % 7) !== 0) {
        cells.push(row);
      } else {
        let insertRow = cells.slice();
        rows.push(insertRow);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice();
        rows.push(insertRow);
      }
    });

    return (
      <Row className="d-block d-lg-none">
        <Col md={12} className="p-0">
          <div className="mt-5 bg-white">
            <div className="d-flex flex-columns py-4">
              <span 
                className="mx-3 calendarIcon"
                alt="calendarIcon" height="33" width="33" >
              </span>
              {this.MonthNav()}

              {this.YearNav()}

              {/* NEXT BUTTON */}
              <span height="33" width="33" 
                onClick={(e) => { this.slideStripLeft(e); }} data-type="reverse" alt="backButton" className="lift prevButton"></span>
              {/* PREVIOUS BUTTON     */}
              <span height="33" width="33" 
                onClick={(e) => { this.slideStripLeft(e); }} data-type="forward" alt="nextButton" className="lift nextButton"></span>
              <button className="todayButton px-4 py-2 ml-3 lift d-none d-lg-block"> THIS MONTH</button>
            </div>
            <div className="d-flex calendarStrip" style={{ overflowX: 'scroll', width: '100%', height:'113px' }}>
              {totalSlots}
            </div>
            <Row >
              <Col md={12} className="p-0">
                {eventsInMonth}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}