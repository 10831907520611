import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import studentHomeStyles from "./StudentHome.module.scss";
import utilityHelper from "../../../helpers/utility";


import { getUserSubscriptions } from "../../../store/actions/userActions";
import { 
  loadDiscussions
} from "../../../store/actions/discussionActions";
import { Row, Col } from 'react-bootstrap';
// import Discussion, { noDiscussionView  } from "../../../components/DicussionCard/Discussion";
import  { DiscussionShimmer } from "../../../components/Shimmer/shimmerArticle";
import CourseProgress from "../../../components/CourseProgress/CourseProgress";
import UpcomingEvents from "../../../components/UpcomingEvents/UpcomingEvent/UpcomingEvent";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { getCourseList , setSectionTitle, setCurrentMenu, getCategories} from "../../../store/actions/coreActions";
import Discussions from "../../../components/DicussionCard/Discussions";
import CourseList from "../../../components/CourseList/CourseList";



const StudentHome = (props) => {
  utilityHelper.setTitleAndScrollToTop("Dashboard:", (props.student || {}).first_name, null);
  const [requested, setRequested] = useState(false);
  const { dispatch, courseList, allCourseList, loadingCourse, loadingUserCourse, categories } = props;
 
  useEffect(() => {
    if(!requested) {
      dispatch(getUserSubscriptions());
      dispatch(loadDiscussions());
      dispatch(getCourseList({}));
      dispatch(setCurrentMenu('dashboard'));
      dispatch(getCategories());
      dispatch(setSectionTitle(
        {
          title: `My Courses`,
          hasHr: false
        }));
      setRequested(true);
    }
  }, [requested, dispatch]);
  




  const ShimmerPlacholder = ()=> (
    <Col md={7} sm={12} className="mt-4">
      <Row className="mt-3 mb-2">
        <Col md={12} className="d-flex justify-content-between 
align-items-center discussion-title-bar">
          <span>Latest Discussions</span>
        </Col>
      </Row>
      <Row >
        <DiscussionShimmer maxCards="2" />
      </Row>
    </Col>
  );
 
  const discussionSection = () => {
    const  discussions = [];
    discussions.discussions =  [...props.discussions.discussions.slice(0,3)];
    // if(discussions.discussions.length){
    return (<Discussions 
      showPagination={false}
      colSize={7}
      className={`${studentHomeStyles.order2}`}
      dispatch={dispatch} 
      addHeader={true}  
      discussionList={discussions} 
      viewCommunity={false} />);
    // }
  };
  // console.log(categories);
  
  return (
    <Fragment>
      <div className={`col-12 pb-5  p-0 min-content-height`}>
        <div className={`col-12 ${studentHomeStyles.Header} p-0`}>
          <div className={studentHomeStyles.HeaderContent} >
            <PageTitle />
          </div>
        </div>
    
       
        <CourseList
          categories={categories}
          subscriptions={courseList}
          allCourses={allCourseList}
          loadingCourse={loadingCourse}
          paginate={false}
          isDashboard={true}
          loadingUserCourse={loadingUserCourse}/>

        <div className={`col-12 p-0`}>
          <Row className={`${studentHomeStyles.divSwitch}`}>
            {!props.discussionsLoading ? discussionSection()  :  ShimmerPlacholder()}
            

            <Col md={5} sm={12} className="d-flex flex-column ">
              <Row>
                <Col md={12} className="p-0 " style={{color: 'transparent', height: '40px'}}>
                  <span>Latest Discussions</span>
                </Col>
              </Row>
              <div className="mt-4">
                <CourseProgress/>
              </div>
              <div className="mt-4">
                <UpcomingEvents/>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    student: state.userState.user,
    loadingUserCourse: state.userState.loadingUserCourse,
    loadingCourse: state.coreState.loadingCourse,
    courseList: state.userState.courseList,
    allCourseList: state.coreState.courseList,
    discussionsLoading: state.discussionState.discussionLoading,
    discussions: state.discussionState.discussionList,
    categories: state.coreState.categories
  };
};

export default connect(mapStateToProps)(StudentHome);
