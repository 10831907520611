export default {
  COURSE_LIST: '/courses/',
  SEARCH_COURSE: '/courses/search',
  COURSE_DETAIL: '/courses/view/@id',
  SUBSCRIBE: '/user-subscriptions/subscribe/@paymentMethod',
  USER_PROFILE: '/users/',
  USER_VIEW: '/users/view/@id',
  USER_EDIT: '/users/',
  USER_EDIT_PASSWORD: '/users/password/change',
  USER_EDIT_BUSINESS: '/users/business-update/',
  USER_AUTH_GOOGLE: '/users/oauth/google',
  USER_AUTH_FACEBOOK: '/users/oauth/facebook',
  USER_RESEND_EMAIL_VERIFICATION: '/users/resend-activation-mail',
  USER_EMAIL_VERIFICATION: '/users/set-active/@secretCode',
  USER_LOGIN: '/users/login',
  USER_SIGN_UP: '/users/add',
  USER_SUBSCRIPTION: '/user-subscriptions/view/',
  USER_SUBSCRIPTION_DETAILS: '/user-subscriptions/details/@id',
  PASSWORD_RESET_REQUEST: '/users/password/order-reset',
  REFRESH_TOKEN: '/users/refresh-token',
  LOAD_ALL_DISCUSSIONS_TOPICS: '/topics/index',
  ADD_COURSE_DISCUSSION: '/discussions',
  CATEGORY_LIST: '/categories/',
  LOAD_COURSE_DISCUSSIONS: '/discussions/@id',
  LOAD_ALL_DISCUSSIONS: '/discussions',
  LOAD_DISCUSSION_TOPICS: '/discussions/discussions-topics',
  LIKE_DISCUSSION: '/discussions/@id/like',
  COMMENT_ON_DISCUSSION: '/discussions/@id/comment',
  LOAD_CATEGORIES: '/categories/',
  SEND_CERTIFICATE: '/user-subscriptions/certificate/email/@subscriptionId',
  FLAG_A_TOPIC: 'topics/@topicId/flag-for-review/',
  LOAD_UPCOMING_EVENTS: 'events?limit=3&filter=upcoming',
  LOAD_EVENTS: '/events/',
  VIEW_EVENT: '/events/@slug',
  BOOK_EVENT: '/events/attend-event/@eventId',
  GET_COMPLETED_COURSE_COUNT: '/user-subscriptions/completed-count',
  GET_PENDING_COURSE_COUNT: '/user-subscriptions/pending-count'
};
  