import React from "react";
import styles from "./Loader.module.scss";

const Loader = (props) => {
  let scale = +props.scale;
  scale = Number.isNaN(scale) ? 1 : scale || 1;
  const scaleStyle = {transform: `scale(${scale})`, ...props.style};
  return props.type === "ripple" ? (
    <div className={`${styles.ripple} `} style={scaleStyle}>
      <div style={{borderColor: props.color || '#4E4E56'}}/>
      <div style={{borderColor: props.color || '#4E4E56'}}/>
    </div>
  ) : (
    <div className={styles.linear} style={scaleStyle}>
      <div style={{background: props.color || '#4E4E56'}}/>
      <div style={{background: props.color || '#4E4E56'}}/>
      <div style={{background: props.color || '#4E4E56'}}/>
    </div>
  );
};

export default Loader;
