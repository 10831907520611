import React, { Fragment, useState, useEffect } from 'react';
import {Card, Row, Col } from 'react-bootstrap';
import './ProfileCard.scss';
import { LazyImage } from '../CourseItem/LazyLoadImage';
import  avatar from '../../assets/img/header/avatar.png';
import { connect } from "react-redux";
import PasswordEdit from '../ProfileCardEdit/ChangePassword';
import ProfileCardEdit from '../ProfileCardEdit/ProfileCardEdit';
import { 
  setCurrentMenu , SHOW_SNACKBAR
} from "../../store/actions/coreActions";
import studentHomeStyles from "../../containers/pages/StudentHome/StudentHome.module.scss";
import http from '../../helpers/http';
import apiRoutes from '../../constants/apiRoutes';
import { SET_USER } from '../../store/actions/userActions';
import Loader from '../Loader/Loader';


const ProfileCard = props =>{
  const [requested, setRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch } = props;

  useEffect(() => {
    if(!requested) {
      dispatch(setCurrentMenu('settings'));
      setRequested(true);
      
    }
  }, [ requested, dispatch]);

  /**
 * returns HTML needed to render details
 * @param {*} key 
 * @param {*} val 
 * @returns {JXS}
 */
  const detailsRow = (key, val)=>{
    return (<div className="d-flex justify-content-between mt-4">
      <span className="key">{key}</span>
      <span className="value">{val}</span>
    </div>);
  };
  const picker = () => {
    document.getElementById('image').click();
  };
  const uploadImage = (event)=>{
    const data = new FormData();
    data.append('image', event.target.files[0]);
    setIsLoading(true);
    http.put(apiRoutes.USER_EDIT, data).then(response => {
      setIsLoading(false);
      props.dispatch({
        type: SET_USER,
        payload: {user: response.data.data}
      });
      props.dispatch({ type: SHOW_SNACKBAR, payload: {
        config: {message: response.data.message}
      } });
    });
  };
  /**
   * Profile Section card
   * @returns {Fragment}
   */
  const pCard =()=> {
  //  if(props.student.image.includes('cloudinary')){
  //   const userImage = props.student.image.split('upload');
  //   const formatedImage = `${userImage[0]}upload/w_250,h_250,c_crop,g_face,r_max/w_250${userImage[1]}`;
  //  }
    
    return (<Fragment>
      <div className={`col-12 pb-5  p-0 min-content-height`}>
        <div className={`col-12 ${studentHomeStyles.Header} p-0`}>
         
        </div>
        <div className={`col-12  p-0`}>
          <Row>
            <Col md={8} sm={12} >
              <ProfileCardEdit student={props.student}/>  
              <PasswordEdit />
            </Col>
            <Col md={4} >
              <Fragment>
                <Card className="border-0 profileCard mt-4" >
                  <Card.Header className="profile-card-header my-auto">
                    <p className="text-dark text-center my-3">My Profile</p>
                  </Card.Header>
                  <Card.Body>
                    <div className="d-flex avatar-c-holder flex-column align-items-center">
                      <div className="avatar-c-holder mx-auto d-flex justify-content-center" onClick={picker}>
                        <form name="imageUpload" className="d-none">
                          <input type="file" name="image" id="image" onChange={uploadImage}/>
                        </form>
                        
                        <div>
                          {isLoading ? <Loader scale="0.5" style={{
                            position: 'absolute',
                            top: '54%',
                            fontSize: '2rem',
                            opacity: '1'
                          }}/> : null}
                          <LazyImage 
                            width="24" 
                            height="24" 
                            src={props.student.image || avatar} 
                            alt={props.student.first_name} 
                            className={'rounded-circle avatar-c img img-fluid'}/>
                          <img src={require('../../assets/icons/profile-edit.png')} className="rounded-circle profileEditButton "/>
                        </div>
                      </div>
                      
       
                      <p className="username mt-2">
                        <b>{`${props.student.first_name} ${props.student.last_name}`}</b>
                      </p>
                      <p className="userlocation">{`${props.student.state ? props.student.state+',' : ''}`} {props.student.country}</p>
                    </div>
                    {/* {detailsRow('Email', props.student.email)} */}
                    {/* {detailsRow('Account', props.student && props.student.role ? (props.student.role.name === 'DEMO_CLIENT' ? 'DEMO': 'PAID') : 'DEMO')} */}
                  </Card.Body>
                </Card>
                
                <Card className="border-0 mt-4 profile-card-2 d-none" >
                  <Card.Header className="profile-card-header-2">
                    <Row className="mt-2">
                      <Col md={12} 
                        className=" d-flex justify-content-between align-items-center discussion-title-bar">
                        <span>Business Info</span>
                      </Col>
                    </Row>    
                  </Card.Header>
                  <Card.Body>
                    <div >
                      <p className="title">Business Name</p>
                      <p className="school">{props.student && props.student.business_information ? props.student.business_information.business_name : ''}</p>
                    </div>
                    
                    <div >
                      <p className="education">Business Location</p>
                      <p className="school">{props.student && props.student.business_information ? `${props.student.business_information.state}, ${props.student.business_information.country}`  : ''}</p>
                    </div>
                    <div >
                      <p className="education">Rc Number</p>
                      <p className="school">{props.student && props.student.business_information ? props.student.business_information.rc_number  : ''}</p>
                    </div>
                    <div className="mt-4 certificate d-none">
                      <div className="d-flex justify-content-between">
                        <p className="education">SME Shop Certificate</p>
                        <p className="school">Download</p>
                      </div>
                      
                    </div>
                  </Card.Body>
                </Card>
              </Fragment>

            </Col>
           
        
          </Row>
     
        </div>
      </div> 
    </Fragment>);
  };

  return (
    props.student  ? pCard() :  null 
    
  );
};


const mapStateToProps = state => {
  return {
    student: state.userState.user
  };
};

export default connect(mapStateToProps, null)(ProfileCard);