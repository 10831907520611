import React, { Fragment } from 'react';
import { Col, Card } from 'react-bootstrap';

import { getRandomColor } from '../../helpers/utility';
import DiscussionTopics from './DiscussionTopics';
import { loadDiscussions } from '../../store/actions/discussionActions';
import SidebarTabTitle from '../PageTitle/SidebarTabTile';
const CommunitySidebar = props =>{
  const { discussionTopics, dispatch } = props;

  const loadAllDiscussions = ()=>{
    dispatch(loadDiscussions());
  };

  return (
    <Col md={4} className="mt-4">
      <Fragment>


        {/* <StartDiscussionButton action={() => handleClose(true)}/> */}
        <Card className={`discussionSidebar`}>
          <Card.Header className={`header mt-1 `}>
            <p style={{cursor: 'pointer'}}
              className={`discussionP mb-5`} onClick={loadAllDiscussions}>
              Discussions
            </p>
            <div className="d-flex justify-content-between">
              <SidebarTabTitle title="All Discussion" hasHr={true} isTab={true} classStyle="text-bold"
              />
              <SidebarTabTitle title="Courses I’ve taken" hasHr={false} isTab={true} 
              />
            </div>
           
          </Card.Header>
          <Card.Body>
           
              
            {
              (discussionTopics || [null, null, null, null]).map((topic, index) => {
                const color = getRandomColor(1);
                return <DiscussionTopics
                  key={index} 
                  topic={topic} color={color}
                />;
              })
            }
          </Card.Body>
        </Card>

        <Card className={`discussionSidebar mt-4 d-none`}>
          <Card.Header className={`header mt-1`}>
            Popular Tags
          </Card.Header>
          <Card.Body>
            <p className={`discussionP mb-5`}>All Discussions</p>
              
              
          </Card.Body>
        </Card>
      </Fragment>
    </Col>
  );
};

export default CommunitySidebar;
