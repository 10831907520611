import React, {Fragment} from 'react';
import {sideBarLinks} from '../../constants/general';
import { useHistory } from "react-router-dom";
import MenuItem from '../../components/MenuItem/MenuItem';
import {Col} from 'react-bootstrap';
import './Sidebar.scss';
import appLogo from '../../assets/img/header/logo.png';

/**
     * This works on the menu array to create a menu-list
     * @params {Boolean} isMobile for mobile devices
     * @returns {JSX} of MenuItem
     */
export const parseMenuItems = (isMobile) => {
  
  const sections = Object.keys(sideBarLinks);
  let menu = [];
  sections.forEach(section=> {
    menu.push(<MenuItem
      title={section}
      key={section}
      itemType="header-item"
    /> );
    menu.push(sideBarLinks[section].map(item => <MenuItem
      title={item.name}
      index={item.c}
      icon={item.icon}
      key={item.c}
      did={item.id}
      id={item.routerLink}
      itemType="link-item"
      isMobile={isMobile}
    /> ));
  });
  return menu;
};

const Sidebar = () => {
  const history = useHistory();
  const goTo = () => {
    history.push( '/');
  };
  return (
    <Fragment>
      <Col md={3} lg={3} xl={3} className='d-none d-lg-block border-dark pl-0 z-index-999'>
        <Col md={3} id="logoHolder" style={{    position: 'fixed', top: '30px'}}>
         
          <img src={appLogo} alt="avatar" id="appLogo" style={{
            marginLeft: '3vw',
            cursor: 'pointer'
           
          }} onClick={goTo} />
        </Col>
        <ol className="sidebarOl pl-0"
          style={{    position: 'fixed',
            width: '25%',
            height: '100%',
            top: '98px'}}>
          {parseMenuItems(false)}
        </ol>  
      </Col>
      
    </Fragment>
    
  );

};

export default  Sidebar;
