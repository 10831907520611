import React, { Component } from "react";
import CourseItem from "../../../components/CourseItem/CourseItem";
import utilityHelper from "../../../helpers/utility";
import { connect } from "react-redux";
import { getCourseList,
  appendToCourseList, 
  setCurrentMenu, 
  getCategories, setSectionTitle } from "../../../store/actions/coreActions";
import { Route, Switch } from "react-router-dom";
import {courseCategories, paystackPaymentLink} from "../../../constants/general";
import { extractQueryParams, 
  updateQueryParams, insertUrlParam, 
  attachAccessTokenToUrlQueryParams, 
  hardRedirect } from "../../../helpers/url";
import {  Row, Col } from 'react-bootstrap';
import Pagination from "../../../components/Pagination/Pagination";
import Shimmer from "../../../components/Shimmer/shimmerArticle";

import CourseDetail from "../StudentCourseDetail/CourseDetail";
import ProgramDetails from "../StudentCourseDetail/ProgramDetails";
import BrowseCourses from "./BrowseCourses";



class Courses extends Component {
  courseCat = courseCategories.map(c => {
    const m = {...c};
    return m;
  });

  state = {
    isLoading: true,
    currentCtg: this.courseCat[0]
  };

  componentDidMount() {
    utilityHelper.setTitleAndScrollToTop("Courses");
    this.props.setCurrentPage('courses');
      
    let queryParams = extractQueryParams(this.props.location.search);
    const { category } = queryParams;
    const ctg = this.courseCat.find(c => c.id === category);
    if(!ctg){
      this.props.history.push(
        updateQueryParams(`${this.props.location.pathname}${this.props.location.search}`, {
          category: undefined
        }));
    }
    this.setState({currentCtg: ctg, isLoading: true});
    queryParams = {...queryParams, 'addToken': true};
    this.props.loadCourses(queryParams);
    this.props.loadCategories();
    this.props.setSectionTitle({
      title:``,
      hasHr: false
    });
    utilityHelper.setTitleAndScrollToTop("Browse Course");
  }

  componentDidUpdate(prevProps, prevState) {
    const loading = !this.props.courseList;
    if(prevState.isLoading !== loading) {
      this.setState({ isLoading: !this.props.courseList });
    }
    if(prevProps.location.search !== this.props.location.search) {
      const queryParams = extractQueryParams(this.props.location.search);
      const { category } = queryParams;
      const ctg = this.courseCat.find(c => c.id === category);
      this.setState({currentCtg: ctg, isLoading: true});
      utilityHelper.scroll();
      this.props.appendCourses(queryParams);
    }
  }
  onEnRollNowClicked = () => {
    const { location } = window;
    const redirectUrl = `${(location ||{}).protocol}//${(location ||{}).host}`;
    let paymentUrl = updateQueryParams(paystackPaymentLink,
      {courseId: this.courseId, redirectUrl});
    if(!utilityHelper.isAuthenticated()) {
      const authUrl = `/auth/sign-up?redirect=${encodeURIComponent(paymentUrl)}`;
      this.props.history.push(updateQueryParams(authUrl, {requireIdentity: 1}));
    }else {
      paymentUrl = attachAccessTokenToUrlQueryParams(paymentUrl);
      hardRedirect(paymentUrl);
    }
  };
 
  
  filterByCatgory = (event) => {
    const location = updateQueryParams(event.target.options[event.target.selectedIndex].getAttribute('to'));
    const newurl = insertUrlParam('category',location);
    const queryParams = extractQueryParams(newurl);
    const ctg = this.props.categories.find(c => {
      return c.name === location;});
    
    if(!ctg){
      this.props.history.push(
        updateQueryParams(`${this.props.location.pathname}${this.props.location.search}`, {
          category: undefined
        }));
    }
    this.setState({currentCtg: ctg, isLoading: true});
    this.props.loadCourses(queryParams);
    
  }
  coursePlaceholder = ()=> (  <div className="col-12 p-0" id="courseWrapper">
    <Row><Shimmer maxCards="4" /> </Row></div>);

  courseWrapper = () => this.props.courseList.courses ? (
    <div className="col-12 p-0" id="courseWrapper">
      <Row>
        {this.props.courseList.courses.map((c, index) => {
          return (
            <Col sm={12} md={4} lg={4} className="pt-4" key={index}>
              <CourseItem key={index} course={c}/>
            </Col>
          );
        })}
        { this.state.isLoading ? <Shimmer /> : ""} 
      </Row>
      <Pagination list={this.props.courseList} updateWho="course"/>
      
    </div>
  ) : null;

  render() {
    return (
      
      <Switch>
        <Route path={`${this.props.match.path}/programs/:id`} component={ProgramDetails}  exact/>
        <Route path={`${this.props.match.path}/:id`} component={CourseDetail}  exact/>
        <Route path={`${this.props.match.path}/`} component={BrowseCourses}  />
      </Switch>

    );
  }
}

const mapStateToProps = state => {
  return {
    courseList: state.coreState.courseList,
    categories: state.coreState.categories
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadCourses: params => dispatch(getCourseList(params)),
    appendCourses: params => dispatch(appendToCourseList(params)),
    setCurrentPage: params=> dispatch(setCurrentMenu(params)),
    loadCategories: () => dispatch(getCategories()),
    setSectionTitle: params =>dispatch(setSectionTitle(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
