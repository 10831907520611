import React, { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import CourseItem from '../CourseItem/CourseItem';
import Shimmer from '../Shimmer/shimmerArticle';
import studentHomeStyles from "../../containers/pages/StudentHome/StudentHome.module.scss";
import Pagination from '../Pagination/Pagination';
import { useSelector } from 'react-redux';

const CourseList = props => {
  // const categories = useSelector(state=>state.coreState.categories);
  const {
    user,
    subscriptions,
    allCourses,
    loadingCourse,
    loadingUserCourse,
    paginate,
    isDashboard,
    categories
  } = props;
  const courses = (subscriptions || {}).courses;
  const allCourse = (allCourses || {}).courses;
  const filteredByCategory = useSelector(state => state.coreState.courseCategories);
  const renderCourses = (limit = 0) => {

    if (!loadingCourse && !loadingUserCourse && courses) {
      let taking = [];
      let completed = [];
      // if user has subscriptions
      completed = courses.filter(c => c.is_completed);
      if (limit > 0) {
        taking = courses.filter(c => !c.is_completed).slice(0, limit);
        // completed = [];
      } else {
        taking = courses.filter(c => !c.is_completed);
        // completed = courses.filter(c => c.is_completed);
      }
      

      return (
        <Fragment>
          <div className={`col-12  p-0 `}>
            <Row>
              {
                taking && taking.length > 0 ? (
                  <Fragment >
                    {taking.map((c, index) => { 
                      
                      return (
                        <Col sm={12} md={4} lg={4} className="pt-4 " key={index}>
                          <CourseItem  
                            category={c.course.Category} 
                            key={index} 
                            course={c} 
                            forStudent={true} />
                        </Col>
                      );
                    }
                    )
                    }
                  
                  </Fragment>) : null
              }
              {/* {completed && completed.length ? (
                completed.map((c, index) => (
                  <Col sm={12} md={4} lg={4} className="pt-4 " key={index}>
                    <CourseItem key={index} course={c} forStudent={true} />
                  </Col>
                ))) : null} */}


            </Row>
            {
              paginate && courses ?
                <Pagination
                  list={subscriptions}
                  updateWho="sub"
                  title="See more courses" />
                : null
            }
          </div>


        </Fragment>);
    }
  };
  const renderFreeCourses = (limit = 3) => {

    // course and subs not loading
    // subs is empty
    // user is not a paid client
    // then show allCourses
    if (!loadingCourse &&
      !loadingUserCourse &&
      !(courses || {}).length &&

      allCourse) {
      const freeCourse = allCourse.slice(0,limit);

      return (
        <Fragment>
          <div className={`col-12  p-0 `}>

            <Row>
              {
                freeCourse.slice(0, 3).map((c, index) => {
                  
                  return (<Col lg={4} className="pt-4 d-none d-lg-block" key={index}>
                    <CourseItem 
                      category={c.Category} 
                      key={index} course={c} />
                  </Col>);
                })}
            </Row>
            <div className={`${studentHomeStyles.horizontalSlider} d-block d-lg-none`}>
              <div className={`${studentHomeStyles.sliderContainer}`}>
                {
                  freeCourse.slice(0, 3).map((c, index) => {
                    return (<Col sm={9} className="pt-4" key={index}>
                      <CourseItem 
                        category={c.Category} 
                        key={index} course={c} />
                    </Col>);
                  })}
              </div>
            </div>
            {
              paginate && allCourse ?
                <Pagination
                  list={allCourse}
                  updateWho="course"
                  title="See more courses" />
                : null
            }
          </div>

        </Fragment>);
    }
  };
  /**
   * @name nextCourse
   * this slides the div to the right
   * Slide course holder forward by 328 which is the size of one card
   * @param {*} event 
   * 
   */
  const nextCourse = (event) => {
   
    const elem = document.querySelector(`#${event.target.parentElement.parentElement.parentElement.id} > #courseSlider`);
    if(elem.scrollLeft === 0){
      document.querySelector(`#${event.target.parentElement.id} > .flipRight`).classList.add('d-none');
    }else{
      document.querySelector(`#${event.target.parentElement.id} > .flipRight`).classList.remove('d-none');
    }
    const left = elem.scrollLeft + 328;
    elem.scroll({
      left: left,
      behavior: 'smooth'  // 👈 
    });
  };
  const prevCourse = (event) => {
   
    const elem = document.querySelector(`#${event.target.parentElement.parentElement.parentElement.id} > #courseSlider`);
    if(elem.scrollLeft === 0){
      document.querySelector(`#${event.target.parentElement.id} > .flipRight`).classList.add('d-none');
    }else{
      document.querySelector(`#${event.target.parentElement.id} > .flipRight`).classList.remove('d-none');
    }
    const right = elem.scrollLeft - 328;
    elem.scroll({
      left: right,
      behavior: 'smooth'  // 👈 
    });
  };
  const renderAllCourses = (limit = 0, fromDashboard = false) => {
    // course and subs not loading
    // subs is empty
    // user is not a paid client
    // then show allCourses
   
    if (!loadingCourse &&
      !loadingUserCourse &&
      // !(courses || {}).length && 
      !fromDashboard &&
       categories) {
        
      let data = categories;
      if(limit){
        data = categories.slice(0, limit);
      }
      if(filteredByCategory.length){
        data = filteredByCategory;
      }
      return (
        <Fragment>
          <div className={`col-12  p-0 `}>
            {data.map((category, i)=>{
              
              return (
                <div key={`course-parent-${i}`}>
                  
                  <Row id={`row${i}`}>
                    <Col lg={12} className="pt-4 course-category-parent d-flex justify-content-between align-items-center" key={`course-category-parent-${i}`}>
                      <p key={i} className="course-category pt-4  pb-0"> {category.name}</p>
                      <div id={`nextButtonHolder-${i}`}>
                        <img src={require('../../assets/icons/scroll_course.svg')} height="20" width="20" className="mr-4 flipRight d-none" onClick={prevCourse}/>
                        <img src={require('../../assets/icons/scroll_course.svg')} height="20" width="20" className="mr-4" onClick={nextCourse}/>
                      </div>
                     
                    </Col>
                    <div className={`${studentHomeStyles.horizontalSlider} `} id="courseSlider">
                      <div className={`${studentHomeStyles.sliderContainer}`}>
                       
                        {
                          category.Courses.map((c, index) => {

                            return (<Col lg={4} className="pt-2 " key={index}>
                              <CourseItem
                                category={category}
                                key={index}
                                course={c}
                                forStudent={c.is_free}
                                fromAllCourse={true} />
                            </Col>);
                          })}
                      </div>
                    </div>
                  </Row>
                </div>
              );
            })
            }
           
          </div>

        </Fragment>);
    }
  };
  const coursePlaceholder = () => (
    <div className="col-12 p-0" id="courseWrapper">
      <Row>
        <Shimmer maxCards="3" />
      </Row>
    </div>
  );

  return (
    <Fragment>
      {(loadingCourse || loadingUserCourse) ? coursePlaceholder() : null}
      {/* if we on dashboard always only show 3 courses to maintain design  */}
      { isDashboard ? renderCourses() :   ''}
      {/* if we on courses and user is trial or demo then show all course on course page */}
      {!isDashboard && user && ['CLIENT'].includes(user.role.category) ? renderAllCourses() : null}
      {/* if we are on courses page and this client is a paying client then show all his subscriptions */}
      {/* {!isDashboard && user && !['TRIAL_CLIENT', 'DEMO_CLIENT'].includes(user.role.name) ? renderCourses() : null} */}

      {isDashboard ? renderFreeCourses() : null}

    </Fragment>
  );
};
export default CourseList;