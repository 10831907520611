import React from 'react';
import './CourseProgress.scss';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ProgressBar from '../UI/CircularProgressBar/ProgressBar';


const CourseProgress = () =>{
  const completedCourseCount = useSelector(state=>state.userState.completedCourseCount);
  const pendingCourseCount = useSelector(state=>state.userState.pendingCourseCount);


  return (<Card className="border-0 p-2 radius20">
    <Card.Body >
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column align-items-center courseProgress">
          <ProgressBar
            title="Course taken"
            count={pendingCourseCount || 0}
            progress={( pendingCourseCount/(pendingCourseCount+completedCourseCount) * 100)}
            size={150}
            strokeWidth={9}
            circleOneStroke='#e3e6e9'
            circleTwoStroke='#0062FF'>
            {/* <img 
              style={{height: '30px',position: 'absolute',top: '32%'}}
              src={require('../../assets/icons/pending.svg')} 
              alt="slider"/> */}
            
          </ProgressBar>
        </div>
        <div className="d-flex flex-column align-items-center courseProgress">
          <ProgressBar
            title="Completed"
            count={completedCourseCount || 0}
            progress={( completedCourseCount/(pendingCourseCount+completedCourseCount) * 100)}
            size={150}
            strokeWidth={9}
            circleOneStroke='#e3e6e9'
            circleTwoStroke='#14A277'>
            {/* <img 
              style={{height: '30px',position: 'absolute',top: '32%'}}
              src={require('../../assets/icons/completed.svg')}
              alt="slider"/> */}
            
          </ProgressBar>
        </div>
        {/* <div className="d-flex flex-column align-items-center courseProgress">
          <img src={require('../../assets/icons/user-dashboard/customer.png')} alt="slider"/>
          <p className="coursecount">{ (pendingCourseCount) || 0 } </p>
          <p className="courseCountTitle">Course taken</p>
        </div>
        <div className="d-flex flex-column align-items-center courseProgress">
          <img src={require('../../assets/icons/user-dashboard/sales.png')} alt="slider"/>
          <p className="coursecount">{ (completedCourseCount) || 0 } </p>
          <p className="courseCountTitle">Completed</p>
        </div> */}
      </div>
    </Card.Body>
  </Card>);
};
export default CourseProgress;