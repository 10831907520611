import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { BigImageShimmer, LineShimmer } from '../../Shimmer/shimmerArticle';
const LoadingCalendarDetails = ()=>{

  return (
    <Row>
      
      <Col md={8}>
        <Row>
           
          <Col md={12} className="p-0">
            <BigImageShimmer height='300px' width='600px'/>

          </Col>
          <Col md={12} className="p-0 mt-3 h-100 d-block d-lg-none">
            <Card style={{border:'none'}} className="eventAction">
              <Card.Body className="text-center ">
                <BigImageShimmer height='71px' width='80px' shineBoxParentAddClass="justify-content-center"/>
                
                <b className="mt-3">Date and Time</b>
      
                <Card.Text className="text-center">
                  {<LineShimmer lines="1" />}
                </Card.Text>
                <Card.Text className="text-center">
                  {<LineShimmer lines="1" />}
                </Card.Text>
                {<LineShimmer lines="1" shineLineAddClass="button"/>}
              </Card.Body>
            </Card>
          </Col>
  
          <Col md={12} className="p-0 mt-4">
            <Card style={{border:'none'}} className="eventCardDetails">
             
              <Card.Body className="py-5 px-4">
                <Card.Title>
                  {<LineShimmer lines="1"  shineLineAddClass="headerText"/>}
                  
                </Card.Title>
                <hr className="d-block d-lg-none"/>
                <br/>
                {<LineShimmer lines="4"  shineLineAddClass="veryLong"/>}
              
              </Card.Body>
            </Card>
           
          </Col>
        </Row>
      </Col>
      <Col md={4} className="h-100 d-none d-lg-block">
        <Card style={{border:'none'}} className="eventAction">
          <Card.Body className="text-center ">
            <BigImageShimmer height='71px' width='80px' shineBoxParentAddClass="justify-content-center"/>
            <b className="mt-3">Date And Time</b>
      
            <Card.Text className="text-center">
              {<LineShimmer lines="1" />}
            </Card.Text>
            <Card.Text className="text-center">
              {<LineShimmer lines="1" />}
            </Card.Text>
            {<LineShimmer lines="1" shineLineAddClass="button"/>}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  
  );
};
export default LoadingCalendarDetails;