import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import "./StudentCourseDetail.scss";

import utility, { CurrencyFormatter } from "../../../helpers/utility";
import {Tabs, Tab,  Button} from 'react-bootstrap';
import utilityHelper from "../../../helpers/utility";

import { SET_PAGE_BACK_BUTTON } from '../../../store/actions/coreActions';

import { getUserSubscriptions } from "../../../store/actions/userActions";
import { Link } from "react-router-dom";
import NotFoundPage from "../NotFound/NotFound";
import { attachAccessTokenToUrlQueryParams, 
  hardRedirect, 
  updateQueryParams } from "../../../helpers/url";
import { paystackPaymentLink } from "../../../constants/general";
class CourseDetail extends Component {
  trpLinks = [
    {
      id: "overview",
      text: "Overview",
      link: "#"
    }
  ];
  state = {
    activeTab: this.trpLinks[this.trpLinks.length - 1].id,
    key: 'Overview',
    subscription: {}
  }
  placeHolder =
  `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEA
  AAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=`;

  componentDidMount() {
    const { course, clientType = ''  } = this.props.location.state ? this.props.location.state :  {};
    document.querySelector('#header #pageTitle .page-title').innerText = 'Back to All Courses';
    this.props.dispatch({
      type: SET_PAGE_BACK_BUTTON, 
      payload: { action: true, location: '/courses' } 
    });  
    
    utility.setTitleAndScrollToTop(
      course ? course.title : ''
    );

    if(['CLIENT'].includes(clientType)){
      this.props.dispatch(getUserSubscriptions());
    }
    
  }
  

  componentDidUpdate(prevProps){
    if(prevProps.subscriptions !== this.props.subscriptions){
      const subId = this.props.subscriptions.filter(subscription => {
        return this.props.location.state.course.id === subscription.course_id;
      });
      this.setState({ subscription : subId[0] });
    }
  }
  
setKey = (key)=>{
  this.setState({key: key});
};

getValidUrl = (url = "") => {
  var pattern = /^((http|https|ftp):\/\/)/;
  if(!pattern.test(url)) {
    url = "http://" + url;
  }
  return url;
};

onEnRollNowClicked = (courseId) => {
      
  const { location } = window;
  const redirectUrl = `${(location || {}).protocol}//${(location || {}).host}/payment-success`;
  let paymentUrl = updateQueryParams(paystackPaymentLink,
    { courseId , redirectUrl, regType: 'course' });

  if (!utilityHelper.isAuthenticated()) {
    const authUrl = `/auth/sign-up?redirect=${encodeURIComponent(paymentUrl)}`;
    this.props.history.push(updateQueryParams(authUrl, { requireIdentity: 1 }));
  } else {
    paymentUrl = attachAccessTokenToUrlQueryParams(paymentUrl);
    hardRedirect(paymentUrl);
  }
};


 renderCourseOverview = course => {
   return (
     <div className="py-3 overview">
       <div>
       
         <div className="d-flex justify-content-between pr-2">
           <b>About Course</b>
           
           <b>{CurrencyFormatter(course.cost|| 0)}</b>
         </div>
         <p className="mt-3">{course.description}</p>
       </div>
       <b>Syllabus</b>
      
       <br/>
       <br/>
       {
         course.modules.map((module, i)=> {
           return (
             <div key={i}>
               <b>Lesson {i+1}: <span>{module.title}</span></b>
               <p className="mt-3">{module.description}</p>
             </div>
           );
         })
       }
     </div>
   )
   ;
 };

paymentLink = (course) => ( 
  <Fragment>
    <Button
      variant="link"
      style={{textDecoration: 'none'}}
      className={`px-5  btn  lift sme-btn-primary mr-2  paymentBtn  paymentButton`}
      onClick={() => this.onEnRollNowClicked(course.id)}>
                    Register
    </Button>
    
   
  </Fragment>
  
);
startCourseButton = () => this.state.subscription ? (
  <Link
    className="paymentButton"
    to={{
      pathname: `/classroom/${this.state.subscription.id}`
    }}>
    <Button className={`no-lift sme-btn-primary addBtnArrow `} >
      Start Course
    </Button>
  </Link>
) : null ;



render() {
  const { course= {} } = this.props.location.state ? this.props.location.state :  {};
  const { 
    title, 
    modules=[],
    image
  } = course || {};
  const { module_topic } = modules[0] || [];

  return title ? (
    <div className=" row  StudentCourseDetail">

      <div className="col-md-8 col-sm-12 mx-auto p-0">
        {
          ( module_topic[0] || []).resource ?
            <iframe title={title} 
              src={`${( module_topic[0] || []).resource  }?autoplay=1&loop=1&autopause=0width=640&height=360`} 
              srcDoc={`<style>*{padding:0;margin:0;overflow:hidden}
      html,body{height:100%}img,span{position:absolute;width:100%;
        top:0;bottom:0;margin:auto}span{height:1.5em;
          text-align:center;font:48px/1.5 sans-serif;color:white;
          text-shadow:0 0 0.5em black}</style>
        <a href=${( module_topic[0] || []).resource}?autoplay=1&loop=1&autopause=0width=640&height=360>
        <img src=${image || this.placeHolder} alt=${title}>
        <span>▶</span></a>`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-100 normalplayer"
            ></iframe>  : 
            <img src={`${image || this.placeHolder}`} alt={`${title}`} className="w-100"/>
        }
        
      </div>
      
      <div className="col-md-8 col-sm-12 mx-auto bg-white w-100 tab-holder px-4 mt-4 radius20 mb-4">
        <Tabs
          id="controlled-tab-example"
          activeKey={this.state.key}
          onSelect={(k) => this.setKey(k)}
        >
          <Tab
            eventKey={this.trpLinks[0].text} 
            title={this.trpLinks[0].text}
            key={0}>
            {
              this.props.subscriptions && this.props.subscriptions.filter(sub => sub.course.id === course.id).length  ? 
                this.startCourseButton() : this.paymentLink(course) 
            }
            <div className="p-2"> { this.renderCourseOverview(course) }  </div>
          </Tab>
        </Tabs>
      </div>

    </div>
  ) : <NotFoundPage/>;
  
  
}
}

const mapStateToProps = state => {
  return {
    currentSubscription: state.userState.currentSubscription,
    subscriptions: state.userState.courseList.courses,
    snackbarState: state.coreState.snackbar
  };
};

export default connect(mapStateToProps, null)(CourseDetail);
