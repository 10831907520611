import React, { useState, useEffect, Fragment } from 'react';

import '../UpcomingEvents.scss';
import '../../../assets/scss/custom-buttons.scss';

import { connect } from 'react-redux';
import { SET_PAGE_BACK_BUTTON, SET_PAGE_SECTION_TITLE } from '../../../store/actions/coreActions';

import { loadEvents, loadUpcomingEvents } from '../../../store/actions/eventsActions';

import NoEventView from '../noEvents';
import EventCard from '../EventCard/EventCard';
import { Link } from 'react-router-dom';

const UpcomingEvents = props =>{
  const [requested, setRequested] = useState(false);
  const { dispatch } = props;
  useEffect(() => {
    if (!requested) {
      dispatch({
        type: SET_PAGE_SECTION_TITLE, setPageTitle: {
          title: `Calendar`,
          hasHr: false
        }
      });
      dispatch(loadEvents());
      dispatch(loadUpcomingEvents({limit: 3, filter: 'upcoming',  type: 'event'}));
      dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: false, location: '/' } });
      setRequested(true);
    }
  }, [requested, dispatch]);

  

  const eventContents = (events) => {
    if(events.upcomingEvents.events.length){
      return events.upcomingEvents.events.map((event, index) => (
        <EventCard event={event} index={index} key={index} />
      ));
    }else{
      return (<NoEventView/>);
    }
    
  };
  return(<Fragment>
    <div  className="row"> 
      <div  
        className="col-md-12 d-flex justify-content-between align-items-center px-4" 
      > 
        <b className="eventHeader">
        Upcoming Events
        </b>
        <Link to={`/calendar/all`} className="allEventLink d-none">
          View All Events
        </Link>
      </div>
      {
        !props.event.upcomingEvents.loading ?  eventContents(props.event) : null
      }
    </div>
    
  </Fragment>);
};

const mapStateToProps = state => {
  return {
    event: state.eventState
  };
};
export default connect(mapStateToProps)(UpcomingEvents);