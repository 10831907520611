import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { updateQueryParams } from "../../helpers/url";
import { getListFromResponse } from "../../helpers/utility";
import { SHOW_SNACKBAR } from "./coreActions";

export const LOAD_EVENTS = "SET/EVENTS";
export const LOAD_UPCOMING_EVENTS = "SET/UPCOMINGEVENTS";
export const UPDATE_EVENTS = "UPDATE/UPCOMINGEVENTS";
export const VIEW_AN_EVENT = 'GET/EVENT';
export const BOOK_AN_EVENT = 'BOOK/EVENT';

export const loadEvents = () => {
  return function(dispatch) {
    return http.get(updateQueryParams(apiRoutes.LOAD_EVENTS, { limit: 10, type: 'event' }) )
      .then(response => {
        dispatch({
          type: LOAD_EVENTS, 
          payload: {events : getListFromResponse(response, 'events'), loading: false}
        });
      });
  };
};
export const viewEvent = (eventId, userId) => {
  return function(dispatch) {
    return http.get(
      updateQueryParams(apiRoutes.VIEW_EVENT.replace('@slug',eventId ), { 
        user_id: userId 
      }))
      .then(response => {
        dispatch({
          type: BOOK_AN_EVENT, 
          payload: {event : response.data.data, loading: false, booking: false}
        });
       
      });
  };
};
export const bookEvents = (eventId) => {
  return function(dispatch) {
    dispatch({
      type: BOOK_AN_EVENT, 
      payload: {loading: false, booking: true}
    });
    return http.post(apiRoutes.BOOK_EVENT.replace('@eventId',eventId ) )
      .then(response => {
        
        dispatch({
          type: BOOK_AN_EVENT, 
          payload: {event : response.data.data, booking: false, loading: false}
        });
        dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: response.data.message } } });
      });
  };
};
export const loadUpcomingEvents = (params) => {
  return function(dispatch) {
    return http.get(updateQueryParams(apiRoutes.LOAD_EVENTS, {...params}) )
      .then(response => {
        dispatch({
          type: LOAD_UPCOMING_EVENTS, 
          payload: {upcomingEvents:  getListFromResponse(response, 'events'), loading: false}
        });
      });
  };
};




