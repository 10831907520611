import React, {Fragment} from 'react';
import { Button } from 'react-bootstrap';


export const StartDisicussionSmallScreen = props => {
  return (
    <Button onClick={props.action} className="d-block d-lg-none sme-btn-primary mb-4 btn-block p-3 lift">
      <span className="mr-3">+</span> Start new Discussion
    </Button>
  );
};

const StartDiscussionButton = props => {
  return (
    <Fragment>
      
      <Button onClick={props.action} className={`d-none d-lg-block  sme-btn-primary mb-4 btn-block p-2 lift`}>
       
        <span className="mr-2">+</span> Start new Discussion
      </Button>
    </Fragment>

  );
};
export default StartDiscussionButton;