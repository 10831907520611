import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { certificateLink } from '../../constants/general';
import './CourseItem.scss';
import { Button } from 'react-bootstrap';
import ProgressBar from '../ProgressBar/ProgressBar';
import { LazyImage } from "./LazyLoadImage";
import { attachAccessTokenToUrlQueryParams } from "../../helpers/url";

const ProgramDetails = props => {
  // console.log(props)
  const user = useSelector(state => state.userState.user);
  
  return (<Fragment>
    <ProgressBar progress="0%" courseTotal={props.course.topicCount} className="primary-progress" />
    <Link to={{
      pathname: `/courses/programs/${props.course.slug}`,
      params: {id: props.course.id},
      state: {
        clientType: user.role.category,
        course: { ...props.course },
        category: { ...props.category },
        tags: { ...props.tags },
        forStudent: props.forStudent
      }
    }}
    title={
      props.course.modules && props.course.modules.length ? 
        props.course.slug : 'disabled no course information'
    }
    className={`CourseItemLink `}>
      <Button className={`no-lift sme-btn-primary addBtnArrow `} >
      See Program Details
      </Button>
    </Link>
  </Fragment>
  );
};
const Details = props => {
  const user = useSelector(state => state.userState.user);
  
  return (<Fragment>
    <ProgressBar progress="0%" courseTotal={props.course.topicCount} className="primary-progress" />
    <Link to={{
      pathname: `/courses/${props.course.slug}`,
      params: {id:props.course.id},
      state: {
        clientType: user.role.category,
        course: { ...props.course }
      }
    }}
    title={
      props.course.modules && props.course.modules.length ? 
        props.course.slug : 'disabled no course information'
    }
    className={`CourseItemLink ${props.course.modules && props.course.modules.length ? '' : 'disabled'}`}>
      <Button className={`no-lift sme-btn-primary addBtnArrow `} >
      See Course Details
      </Button>
    </Link>
  </Fragment >
  );
};

const Resume = props =>  {
  const subscriptions = useSelector(state => state.userState.courseList.courses);
  if(subscriptions){
    const sub = subscriptions.filter(subItem => subItem.course.id === props.course.id)[0];
    if(sub){
      return (
        <Fragment>
          <ProgressBar className="success-progress" courseTotal={(sub.progress || {}).topicRemaining || 0} courseCompleted={(sub.progress || {}).completedTopics || 0} progress={`${(sub.progress || {}).percentageCompleted || 0}%`} />
    
          <Link
            className={`CourseItemLink ${sub.progress  ? '' : 'disabled'}`}
            to={{
              pathname: `/classroom/${sub.id}`,
              state: {
                certificateLink: ((sub.course.has_certificate && certificateLink && certificateLink.length ) 
                  ?  attachAccessTokenToUrlQueryParams(`${certificateLink}`.replace(':id',sub.id))
                  :'')
              }
            }}>
            <Button 
              className={`no-lift btn sme-btn-primary 
            ${(sub.progress || {}).percentageCompleted === 0 ? '' : 
          'faded-primary-btn'}`} >
              { 
                (sub.progress || {}).percentageCompleted === 0  ? 
                  'Start Course' :  'Continue course'
              } 
            </Button>
          </Link>
    
        </Fragment>
      );
    } else {
      return (<Details course={props.course} /> );
    }
  } else {
    return (<Details course={props.course} /> );
  }
  
};


const CourseItem = props => {
  const { category } = props;
  const sub = props.course;
  let course = sub;
  if(props.forStudent && props.fromAllCourse){
    course = sub;
  } else if(props.forStudent && !props.fromAllCourse){
    course = sub.course;
  }
  const showCourseButton = () => {
    if(course.delivery_type.includes('on-site') || course.delivery_type.includes('live')){
      {
        return <ProgramDetails  
          course={course} 
          category={category}
          forStudent={props.forStudent} />;
      }
    }else{
      {return  !props.forStudent ? 
        <Details course={course} /> 
        :(!sub.is_completed  ? 
          <Resume 
            course={course} 
            subObject={sub} /> : 
          <Resume 
            course={course} 
            subObject={sub} 
            certificateLink={certificateLink}/>);}
    }
    
  };
  const content = (
    <div id={course.id} className={`card lift  border-0 w-100 h-100 bg-transparent CourseItem `}>
      <div className={`card-header p-0 no-border radius-top-20`}>
        <LazyImage src={course.image} alt={course.title} className={'CourseImage card-img-top'} />
      </div>
      <div className={`card-body d-flex flex-column
      cardBody text-secondary`}>
        <h2 className={`courseName max-line max-line-1`}>{course.title || 'Course Name'}</h2>
        <p className="card-title max-line max-line-2"> {course.description || 'Course Description'}</p>
      </div>
      <div className="card-footer border-0 radius-bottom-20"> 
        <div className="d-flex flex-column justify-content-between contentWrapper">
          { showCourseButton() }
        </div>
      </div>
    </div>
  );
  return (
    content
  );
};

export default CourseItem;
