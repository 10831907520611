import * as actionTypes from "../actions/coreActions";
import { courses } from "../../constants/mockData";


const initialState = {
  snackbar: {
    open: false,
    changed: false,
    config: {}
  },
  alert: {
    open: false,
    config: {}
  },
  setBackButton: {},
  courseCategories: [],
  categories: null,
  loadingCourse: true,
  courseList: null,
  currentCourse: null,
  recommended: courses.filter(c => c.recommended),
  currentPage: 'dashboard',
  sectionTitle: {
    title: 'Dashboard',
    hasHr: false
  },
  notifications: []
};
let updatedCourseList;
const coreReducer = (state = initialState, action) => {
  try {
    switch (action.type) {
      case actionTypes.LOADING_COURSE:
        state.loadingCourse = action.payload.loadingCourse;
        return{
          ...state
        };
      case actionTypes.SET_PAGE_BACK_BUTTON:
        return {
          ...state,
          setBackButton:{
            ...action.payload
          }
        };
      case actionTypes.SET_NOTIFICATIONS:
        return {
          ...state,
          notifications: [...action.payload.notifications]
        };
      case actionTypes.SHOW_SNACKBAR:
        return {
          ...state,
          snackbar: {
            ...state.snackbar,
            open: true,
            changed: action.payload.changed,
            config: action.payload.config
          }
        };
      case actionTypes.SHOW_ALERT:
        return {
          ...state,
          alert: {
            ...state.alert,
            open: true,
            config: { ...action.payload }
          }
        };
      case actionTypes.CLEAR_ALERT:
        return {
          ...state,
          alert: {
            ...state.alert,
            open: false,
            config: {
              
            }
          }
        };
      case actionTypes.SET_CATEGORIES:
        return {
          ...state,
          categories: action.payload.categories
        };
      case actionTypes.SET_COURSE_CATEGORIES:
        return {
          ...state,
          courseCategories: action.payload.categories
        };
      case actionTypes.CLEAR_SNACKBAR:
        return {
          ...state,
          snackbar: {
            ...state.snackbar,
            open: false,
            config: {
              ...state.snackbar.config,
              message: ""
            }
          }
        };
      case actionTypes.SET_COURSE_LIST:
        state.loadingCourse = action.payload.loadingCourse;
        return {
          ...state,
          courseList: action.payload.courseList
        };
      case actionTypes.UPDATE_COURSE_LIST:
        updatedCourseList = state.courseList.courses
          .concat([ ...action.payload.courseList.courses]);
        return {
          ...state,
          courseList: {
            ...state.courseList,
            courses: updatedCourseList,
            next_page: action.payload.courseList.next_page,
            number_of_pages: action.payload.courseList.number_of_pages,
            total: action.payload.courseList.total,
            limit: action.payload.courseList.limit,
            page: action.payload.courseList.page
          }
        };
      case actionTypes.SET_CURRENT_COURSE:
        return {
          ...state,
          currentCourse: action.payload.currentCourse || 
            ((state.courseList || {}).courses || []).find(c => (
              ''+c.id === ''+action.payload.id
            ))
        };
      case actionTypes.SET_CURRENT_MENU_ITEM:
        return { ...state, currentPage : action.setCurrentPage.currentPage };
      case actionTypes.SET_PAGE_SECTION_TITLE:
        return { ...state, sectionTitle: {...action.setPageTitle } };
      default:
        return state;
    }
  } catch (e) {
    return state;
  }
};

export default coreReducer;
