import React, {Fragment, useRef } from 'react';
import './PageTitle.scss';

import http from "../../helpers/http";
import apiRoutes from "../../constants/apiRoutes";
import { LOAD_DISCUSSION } from '../../store/actions/discussionActions';
import { connect } from 'react-redux';
import { getListFromResponse } from '../../helpers/utility';

const TabTitle = props => {
  const ref = useRef(props.title);
  const toggleClick = (e)=>{
    
    document.querySelectorAll('.tabTitle').forEach(el => {
      el.classList.remove('hasHr');
      el.classList.add('noHr');
    });
    if(e.target.classList.contains('noHr')){
      e.target.classList.add('hasHr');
      e.target.classList.remove('noHr');
    }else{
      
      e.target.classList.remove('hasHr');
      e.target.classList.add('noHr');
     
    }
    
    if(e.target.id.toLowerCase() === 'trending'){
      http.get(apiRoutes.LOAD_ALL_DISCUSSIONS.concat('?trending=true')).then(response => {
        props.dispatch({
          type: LOAD_DISCUSSION, 
          payload: {discussions:  getListFromResponse(response, 'discussions'), loading: false}
        });
      });
      
    }  else {
      http.get(apiRoutes.LOAD_ALL_DISCUSSIONS).then(response => {
        props.dispatch({
          type: LOAD_DISCUSSION, 
          payload: {discussions:  getListFromResponse(response, 'discussions'), loading: false}
        });
      });
     
    }
    
  };
  /**
   * isTabbed is used to display this page title in table format, see community section side bar
   * hasHr is used to add a border-bottom to the titme
   */
  return (
     
    <Fragment>
     
      <h3 ref={ref} onClick={toggleClick} id={props.title} 
        className={`${props.classStyle} align-self-center  sectionTitle  
      tabTitle ${props.isTab ? 'greyText font-12' : 'font-weight-600'} ${props.hasHr ? 'hasHr' : 'noHr'} `} >
        {props.title }
       
      </h3> 

      
    </Fragment>
    
  );
};
export default connect()(TabTitle);
