import React, { Component } from "react";
import browseCoursesStyles from "./BrowseCourses.module.scss";

import utilityHelper from "../../../helpers/utility";
import { connect } from "react-redux";
import {
  loadingCourse,
  getCourseList,
  appendToCourseList,
  setCurrentMenu,
  getCategories, setSectionTitle, clearBackButton
} from "../../../store/actions/coreActions";
import { Link } from "react-router-dom";
import { courseCategories, paystackPaymentLink } from "../../../constants/general";
import {
  extractQueryParams,
  updateQueryParams, insertUrlParam,
  attachAccessTokenToUrlQueryParams,
  hardRedirect
} from "../../../helpers/url";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";

import PageTitle from "../../../components/PageTitle/PageTitle";
import { getUserSubscriptions, loadingSub } from "../../../store/actions/userActions";
import CourseList from "../../../components/CourseList/CourseList";




class BrowseCourses extends Component {
  courseCat = courseCategories.map(c => {
    const m = { ...c };
    return m;
  });

  state = {
    isLoading: true,
    currentCtg: this.courseCat[0],
    userSub: [{}]
  };

  componentDidMount() {
    utilityHelper.setTitleAndScrollToTop("Courses");
    this.props.setCurrentPage('courses');
    document.querySelector('#header #pageTitle .page-title').innerText = 'Courses';
    let queryParams = extractQueryParams(this.props.location.search);
    const { category } = queryParams;
    const ctg = this.courseCat.find(c => c.id === category);
    if (!ctg) {
      this.props.history.push(
        updateQueryParams(`${this.props.location.pathname}${this.props.location.search}`, {
          category: undefined
        }));
    }
    this.setState({ currentCtg: ctg, isLoading: true });
    queryParams = { ...queryParams, 'addToken': true };
    this.props.loadCourses(queryParams);
    this.props.loadCategories();
    // this.props.setSectionTitle({
    //   title: `All Courses`,
    //   hasHr: false
    // });
    this.props.getUserSubscriptions();
    this.props.clearBackButton({ action: false, location: '/' });
    utilityHelper.setTitleAndScrollToTop("Browse Course");
  }

  componentDidUpdate(prevProps, prevState) {
    const loading = !this.props.courseList;

    if (prevState.isLoading !== loading) {
      this.setState({ isLoading: !this.props.courseList });
    }
 
    if (prevProps.location.search !== this.props.location.search) {
      const queryParams = extractQueryParams(this.props.location.search);
      const { category } = queryParams;
      const ctg = this.courseCat.find(c => c.id === category);
      this.setState({ currentCtg: ctg, isLoading: true });
      utilityHelper.scroll();
      this.props.appendCourses(queryParams);
    }
  }
  onEnRollNowClicked = () => {
    const { location } = window;
    const redirectUrl = `${(location || {}).protocol}//${(location || {}).host}`;
    let paymentUrl = updateQueryParams(paystackPaymentLink,
      { courseId: this.courseId, redirectUrl });

    if (!utilityHelper.isAuthenticated()) {
      const authUrl = `/auth/sign-up?redirect=${encodeURIComponent(paymentUrl)}`;
      this.props.history.push(updateQueryParams(authUrl, { requireIdentity: 1 }));
    } else {
      paymentUrl = attachAccessTokenToUrlQueryParams(paymentUrl);
      hardRedirect(paymentUrl);
    }
  };
  /**
   * This is the filter section component
   */
  filterSection = () => {
    return this.props.categories ? (
      <div className={`${browseCoursesStyles.filterComponent} d-block d-lg-none`}>
        

        <CustomSelect />

      </div>
    ) : null;
  }
  /**
   * This function returns a list of options
   * for category selection
   */
  generateCategoriesOption = () => {
    const cats = [];
    this.courseCat.forEach((c, index) => { 
      return (
        cats.push(<Link key={index} to={c.link}>{<option key={index} to={c.link}>{c.name}</option>}</Link>)
      );
    });
    return cats;
  };

  filterByCatgory = (event) => {
    const location = updateQueryParams(event.target.options[event.target.selectedIndex].getAttribute('to'));
    const newurl = insertUrlParam('category', location);
    const queryParams = extractQueryParams(newurl);
    // console.log({newurl, queryParams, location});
    const ctg = this.props.categories.find(c => {
      return c.name === location;
    });
    
    // if (!ctg) {
    //   this.props.history.push(
    //     updateQueryParams(`${this.props.location.pathname}${this.props.location.search}`, {
    //       category: ''
    //     }));
    // }
    // this.setState({ currentCtg: ctg, isLoading: true });
  

    if(!this.props.userCourseList.courses && this.props.courseList.courses ){
      this.props.loadingCourses();
      this.props.loadCourses(queryParams);
    } else if(this.props.userCourseList.courses) {
      this.props.loadUserCourse();
      if(ctg){
        this.props.getUserSubscriptions({
          category: ctg.id
        });
      } else {
        this.props.getUserSubscriptions({});
      }
      // this.props.getUserSubscriptions(queryParams);
    
    }

  }
 


  render() {
    return (
      <div className={`col-12 p-0 pb-5   min-content-height`}>
        <div className={`col-12 ${browseCoursesStyles.Header} p-0`}>
          <div className={browseCoursesStyles.HeaderContent} >
            <div className="d-none d-lg-block">
              <PageTitle />
            </div>
            { this.filterSection() } 
          </div>
        </div>
    
        <CourseList
          categories={this.props.categories}
          user={this.props.user}
          isDashboard={false}
          subscriptions={this.props.userCourseList}
          allCourses={this.props.courseList}
          loadingCourse={this.props.loadingCourse}
          paginate={true}
          loadingUserCourse={this.props.loadingUserCourse}/>
        
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loadingCourse: state.coreState.loadingCourse,
    loadingUserCourse: state.userState.loadingUserCourse,
    courseList: state.coreState.courseList,
    categories: state.coreState.categories,
    user: state.userState.user,
    userCourseList: state.userState.courseList
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadingCourses: () => dispatch(loadingCourse()),
    loadUserCourse: () => dispatch(loadingSub()),
    loadCourses: params => dispatch(getCourseList(params)),
    appendCourses: params => dispatch(appendToCourseList(params)),
    setCurrentPage: params => dispatch(setCurrentMenu(params)),
    loadCategories: () => dispatch(getCategories()),
    setSectionTitle: params => dispatch(setSectionTitle(params)),
    clearBackButton: params => dispatch(clearBackButton(params)),
    getUserSubscriptions: params => dispatch(getUserSubscriptions(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowseCourses);
