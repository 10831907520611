import React, {Component, Suspense, lazy} from 'react';
import utilityHelper from "../../../helpers/utility";
import { Container, Row, Col } from 'react-bootstrap';
import DashboardOnboardingScreen from '../../../components/Walkthrough/BigCards';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../../components/Header/Header';

import { connect } from "react-redux";
import PageLoader from '../../PageLoader/PageLoader';
import { Route, Switch } from "react-router-dom";

import StudentHome from '../StudentHome/StudentHome';
import Pricing from '../Pricing/Pricing';
import PaymentComplete from '../Pricing/PaymentComplete';

import ProfileCard from '../../../components/ProfileCard/ProfileCard';

import Calendar from '../Calendar/Calendar';
import Courses from '../BrowseCourses/Courses';
import Notify from '../../../components/UI/Notification/Alert';
import {  showAlert, SHOW_SNACKBAR } from '../../../store/actions/coreActions';
import {resendWelcomeMail, 
  getCompletedCoursesCount, 
  getPendingCoursesCount}from '../../../store/actions/userActions';
import { emailNotification } from '../../../constants/general';
import { SET_PAGE_BACK_BUTTON } from "../../../store/actions/coreActions";
import socket, { SOCKET_ENDPOINTS } from "../../../helpers/socket";
import cookie from '../../../helpers/cookie';
import { setNotifications } from '../../../store/actions/coreActions';
import { extractQueryParams } from '../../../helpers/url';
import PasswordPopup from '../../../components/PasswordPopup/PasswordPopup';
import Community from '../../../components/Community/Community';
import NotFoundPage from '../NotFound/NotFound';

const Classroom = lazy(() => import('../../Classroom/Classroom'));
class Dashboard extends Component{

  state = {
    isLoading: true
  };

  constructor(){
    super();
    this.isAuthenticated = utilityHelper.isAuthenticated();
  }

  componentDidMount(){
    utilityHelper.setTitleAndScrollToTop("Dashboard");
    this.init();
    document.querySelector('#header #pageTitle .page-title').innerText = 'Dashboard';
    document.querySelector('.page-title-holder #pageTitle .page-title').innerText = 'Dashboard';
    this.getNotifications();
    this.props.setBack(); 
    this.queryParams = extractQueryParams(window.location.href); 
    if(this.queryParams.message){
      this.props.notify(decodeURI(this.queryParams.message));
    }
    
  }
  
  getNotifications()  {
    socket.on(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, data => {
      this.props.setNotifications(data);
    });
    socket.emit(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, {
      accessToken: cookie.get("accessToken"),
      filter: 'not-read'
    });
    setInterval(function() {
      socket.emit(SOCKET_ENDPOINTS.GET_NOTIFICATIONS, {
        accessToken: cookie.get("accessToken"),
        filter: 'not-read'
      });
    }, 60*1000); 
    
  }
  init() {
   
    //get currentTheme
    // const currentTheme = localStorage.getItem("theme") || 'dark-theme';
    // if(currentTheme === 'dark-theme'){
    //   const toggle = document.querySelector('.bgToggle');
    //   toggle.checked = true;
    // }
    document.body.classList.add('light-theme');

    if(this.props.user.is_active === false){
      const config =   { 
        message: emailNotification,
        title: 'Account Activation Notification.',
        type:'warning',
        useButton: {
          buttonClass: 'sme-btn-primary',
          text: 'Resend Email', 
          action: () => this.props.resendWelcomeMail(), loading: false } 
      };
      this.props.alert(config);
      this.props.getCompletedCourseCount();
      this.props.getPendingCourseCount();
    }
  }
  
  render(){
    return(
      
      <Container fluid>
        { 
          (this.isLoading) ? 
            <PageLoader/>  :
            ( <DashboardOnboardingScreen />)
        }
        <PasswordPopup/>
        <Suspense fallback={<PageLoader/>}>
          <Header/>
          <Row className="app-content">
           
            <Sidebar/>
            <Col md={9} lg={9}>
              <div className="d-none d-lg-block">
                <Notify/>
              </div>
             
              <Switch>
            
                <Route path="/" component={StudentHome} exact/>
                <Route path="/courses" component={Courses}  />
                <Route path="/pricing" component={Pricing}  exact/>
                <Route path="/settings" component={ProfileCard} exact/>
                {/* <Route path="/settings" component={PasswordEdit} exact/> */}
                <Route path="/community" component={Community} exact/>
                <Route path="/calendar" component={Calendar} />
                <Route path="/classroom" component={Classroom} />
                <Route path="/payment-success" component={PaymentComplete} exact/>
                <Route path="*" component={NotFoundPage} />
              </Switch>
              
            </Col>
          </Row>
        </Suspense>
        
      </Container>
    );
  }
}
const mapStateToProps = state =>{
  return  {
    user: state.userState.user
  };
};
const mapDispatchToProps = dispatch => {
  return {
    notify: params =>  dispatch({ type: SHOW_SNACKBAR, payload: { config: {message: params} } }),
    alert: params => dispatch(showAlert(params)),
    resendWelcomeMail: ()=>dispatch(resendWelcomeMail()),
    setBack: ()=>dispatch({ type: SET_PAGE_BACK_BUTTON, payload: { action: false, location: '/' } }),
    setNotifications: params =>dispatch(setNotifications(params)),
    getCompletedCourseCount: () => dispatch(getCompletedCoursesCount()),
    getPendingCourseCount: () => dispatch(getPendingCoursesCount())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
